import img1 from '../images/box-item/brand-01.png';
import img2 from '../images/box-item/brand-02.png';
import img3 from '../images/box-item/brand-03.png';
import img4 from '../images/box-item/brand-04.png';
import img5 from '../images/box-item/brand-5.png';
import img6 from '../images/box-item/brand-6.png';

const data = [
    {
        id: 1,
        img: 'https://api.createart.live/image/1671628394017',
    },
    {
        id: 2,
        img: 'https://api.createart.live/image/1671628275145',
    },
    {
        id: 3,
        img: 'https://api.createart.live/image/1671702190057',
    },
    {
        id: 4,
        img: 'https://api.createart.live/image/1671628246936',
    },
    {
        id: 5,
        img: 'https://api.createart.live/image/1671706372808',
    },
    {
        id: 6,
        img: 'https://api.createart.live/image/1671622346410',
    },
    {
        id: 7,
        img: 'https://api.createart.live/image/1671629408247',
    },
    {
        id: 9,
        img: 'https://api.createart.live/image/1671628327800',
    },

]

export default data;