import React, {useState, useContext, useEffect} from "react";
import {DataContext} from "../context/dataContext";
import {Languages} from "../languages/languages";
import "../scss/components/section.scss";
import Header from ".././components/header/header.jsx";
import PageTitle from "../components/page-title/page-title-v1";
import Flathelp from "../components/flat-help";
import Footer from "../components/footer/footer-v1";
import dataFooter from "../assets/fake-data/data-footer";
import Bottom from "../components/bottom";
import CardModal from ".././components/CardModal";
import {Dropdown} from "react-bootstrap";
import {Tab, Tabs, TabList} from "react-tabs";
import RangeSlider from "../components/RangeSlider/RangeSlider";
import {EmptyJobCheck, removeEmptyJobFromLocalStorage} from "../Functions";

import axios from "axios";

function Create() {
    const {user, language} = useContext(DataContext);
    let emptyJob =
        JSON.parse(localStorage.getItem("jobs")).find((job) => job.status === "init") ?? EmptyJobCheck(user);
    const [error, setError] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const steps = Array.from(Array(150).keys()).map((i) => i + 1);
    const dimensions = [
        64, 128, 192, 256, 320, 384, 448, 512, 576, 640, 704, 768, 832, 896, 960,
        1024, 1088, 1152, 1216, 1280, 1344, 1408, 1472, 1536, 1600, 1664, 1728,
        1792, 1856, 1920, 1984, 2048,
    ];
    const CFG = [
        1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
        10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5,
        18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25,
        25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30,
    ];
    const hugeQualityArray = [
        0, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21,
        0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34,
        0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47,
        0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6,
        0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73,
        0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86,
        0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99,
        1,
    ];
    const [seed, setSeed] = useState(-1);
    const [check, setCheck] = useState(false);
    const [width, setWidth] = useState(512);
    const [height, setHeight] = useState(512);
    const [cfg, setCfg] = useState(7);
    const [artist, setArtist] = useState("Euler a");
    const [denoise, setDenoise] = useState(0);
    const [positivePrompt, setPositivePrompt] = useState("");
    const [negativePrompt, setNegativePrompt] = useState("");
    const [stepsValue, setStepsValue] = useState(20);
    const [price, setPrice] = useState(25);
    let rates = JSON.parse(localStorage.getItem("Rates"));

    // language.draft_quality + ' - ' + language.draft_quality_bottom_text;//: 'Most cost-effectiv
    // language.basic_quality;//: 'Basic quality',
    // language.basic_quality_bottom_text;//: 'More refined',
    // language.standard_quality;//: 'Standard quality',
    // language.standard_quality_bottom_text;//: 'Double the size
    // language.premium_quality;//: 'Premium quality',
    // language.premium_quality_bottom_text;//: 'More refined',
    // language.supreme_quality;//: 'Supreme quality',
    // language.supreme_quality_bottom_text;//: 'Huge',


    useEffect(() => {
        setTimeout(() => {
            setError("");
        }, 5000);
    }, [error]);
    const [dataDropdown] = useState([
        {
            id: 1,
            title: "Draft",
            description: Languages[language].draft_quality + ' - ' + Languages[language].draft_quality_bottom_text,
            value: 5,
        },
        {
            id: 2,
            title: "Basic",
            description: Languages[language].basic_quality + ' - ' + Languages[language].basic_quality_bottom_text,
            value: 25,
        },
        {
            id: 3,
            title: "Standard",
            description: Languages[language].standard_quality + ' - ' + Languages[language].standard_quality_bottom_text,
            value: 100,
        },
        {
            id: 4,
            title: "Premium",
            description: Languages[language].premium_quality + ' - ' + Languages[language].premium_quality_bottom_text,
            value: 200,
        },
        {
            id: 5,
            title: "Supreme",
            description: Languages[language].supreme_quality + ' - ' + Languages[language].supreme_quality_bottom_text,
            value: 300,
        },
        {
            id: 6,
            title: "Custom",
            description: Languages[language].custom + ' - ' + Languages[language].custom_bottom_text,
            value: "custom",
        },
    ]);
    const [quality, setQuality] = useState(dataDropdown[0]);
    const sendJobWithPayments = async () => {
        let {last_name, first_name, email, phone, meta, uuid} = user;
        if (positivePrompt < 3) {
            setError("Please add at least 3 characters to your masterpiece");
            return null;
        }
        if (!last_name) {
            return setError("Please fill your last name at profile page");
        }
        if (!first_name) {
            return setError("Please fill your first name at profile page");
        }
        if (!meta.zip) {
            return setError("Please fill your zip at profile page");
        }
        if (!meta.state) {
            return setError("Please fill your state at profile page");
        }
        if (!meta.country) {
            return setError("Please fill your country at profile page");
        }
        if (!meta.city) {
            return setError("Please fill your city at profile page");
        }
        if (!meta.address) {
            return setError("Please fill your address at profile page");
        }
        if (!phone) {
            return setError("Please fill your phone at profile page");
        }


        if (!last_name || !first_name || !email || !meta.address || !meta.city || !meta.country || !meta.zip || !meta.state || !phone) {
            setError("Please fill in all profile fields");
            return null;
        }
        if (
            meta.address.length < 3 ||
            meta.city.length < 3 ||
            meta.country.length < 1 ||
            meta.zip.length < 3
        ) {
            setError("Please fill in all profile fields properly");
            return null;
        }
        if (!check) {
            setError("Please accept the terms and conditions");
            return null;
        }
        let settings = {
            cfg_scale: cfg,
            denoising_strength: denoise,
            endable_hr: denoise > 0,
            firstphase_height: 0,
            firstphase_width: 0,
            height: height,
            width: width,
            negative_prompt: negativePrompt,
            prompt: positivePrompt,
            restore_faces: false,
            sampler_index: artist,
            seed: Number(seed),
            steps: stepsValue,
            type: "txt2img",
        };
        let priceData = calculatePriceToSend(
            calculatePrice(quality.title.toLowerCase())
        );
        let dataToSend = {
            merchant_id: "artifusion",
            save: true,
            job: {
                ...emptyJob,
                config: settings,
                status: "init",
                payment: {
                    method: "justtest",
                    amount: priceData.price,
                    currency: priceData.currency,
                    user: {
                        user_uuid: uuid,
                        fname: first_name,
                        lname: last_name,
                        address: meta.address,
                        address2: meta.address2,
                        city: meta.city,
                        province: meta.state,
                        country: meta.country,
                        post_code: meta.zip,
                        phone: phone,
                        email: email,
                    },
                },
            },
        };
        if (dataToSend.job.config.prompt.length < 2) {
            //setError("Please add at least 3 characters to your masterpiece")
            return null;
        }
        await axios
            .post(`https://api.artifusion.shop/pay`, dataToSend, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((res) => {
                removeEmptyJobFromLocalStorage();
                window.location.href = res.data.url;
            })
            .catch((error) => {
            });
    };

    const QualitySetter = (template) => {
        switch (template) {
            case "draft":
                return 512;
            case "basic":
                setStepsValue(40);
                setPrice(25);
                setWidth(512);
                setHeight(512);
                return 512;
            case "standard":
                setStepsValue(30);
                setPrice(100);
                setDenoise(0.7);
                setWidth(1024);
                setHeight(1024);
                return 1024;
            case "premium":
                setStepsValue(40);
                setPrice(200);
                setDenoise(0.7);
                setWidth(1024);
                setHeight(1024);
                return 1024;
            case "supreme":
                setStepsValue(50);
                setPrice(300);
                setDenoise(0.7);
                setWidth(1600);
                setHeight(1600);
                return 1600;
            case "custom":
                setPrice(25);
                setStepsValue(20);
                setPrice(200);
                setDenoise(0);
                setWidth(512);
                setHeight(512);
                return 512;
        }
    };
    const calculatePriceToSend = (eurPrice) => {
        let currency = rates?.currency;
        if (currency === undefined) return {price: eurPrice, currency: "EUR"};
        switch (currency) {
            case "HUF":
                return {
                    price: eurPrice * rates.rates.HUF,
                    currency: "HUF",
                };
            case "USD":
                return {
                    price: eurPrice * rates.rates.USD,
                    currency: "USD",
                };
            case "AUD":
                return {
                    price: eurPrice * rates.rates.AUD,
                    currency: "AUD",
                };
            case "GBP":
                return {
                    price: eurPrice * rates.rates.GBP,
                    currency: "GBP",
                };
            case "NZD":
                return {
                    price: eurPrice * rates.rates.NZD,
                    currency: "NZD",
                };
            case "EUR":
                return {
                    price: eurPrice,
                    currency: "EUR",
                };
            case "ZAR":
                return {
                    price: eurPrice * rates.rates.ZAR,
                    currency: "ZAR",
                };
            case "CAD":
                return {
                    price: eurPrice * rates.rates.CAD,
                    currency: "CAD",
                };
                break;
            default:
                return {
                    price: eurPrice,
                    currency: "EUR",
                };
        }
    };
    const calculatePriceInCurrency = (eurPrice) => {
        let currency = rates?.currency;
        if (currency === undefined) return <>{eurPrice} EUR</>;
        switch (currency) {
            case "HUF":
                return <>{Math.round(eurPrice * rates?.rates.HUF ?? 420)} HUF</>;
            case "USD":
                return <>{Math.round(eurPrice * rates?.rates.USD ?? 1)} USD </>;
            case "AUD":
                return <>{Math.round(eurPrice * rates?.rates.AUD ?? 1.6)} AUD</>;
            case "GBP":
                return <>{Math.round(eurPrice * rates?.rates.GBP ?? 0.9)} GBP</>;
            case "NZD":
                return <>{Math.round(eurPrice * rates?.rates.NZD ?? 1.7)}NZD</>;
            case "EUR":
                return <>{Math.round(eurPrice)} EUR</>;
            case "ZAR":
                return <>{Math.round(eurPrice * rates?.rates.ZAR ?? 19)}ZAR</>;
            case "CAD":
                return <>{Math.round(eurPrice * rates?.rates.CAD ?? 1.4)} CAD</>;
                break;
            default:
                return <>{Math.round(eurPrice * rates?.rates.USD ?? 1)} USD</>;
        }
    };
    const calculatePrice = (template) => {
        let amount = Math.round(
            Math.max(
                5,
                ((width || 512) * (height || 512) * (stepsValue || 20)) / 209715.2
            )
        );

        if (amount > 1000) return 1000;
        switch (template) {
            case "draft":
                return 5;
            case "basic":
                return 25;
            case "standard":
                return 100;
            case "premium":
                return 200;
            case "supreme":
                return 300;
            case "custom":
                return amount;
        }
    };

    return (
        <div className="wrapper-style" id="wrapper">
            {<Header/>}
            {/*<PageTitle title='Create Item'/>*/}
            <>
                <div
                    style={{marginTop: "50px"}}
                    className="tf-section flat-create-item flat-edit-profile flat-auctions-details flat-explore flat-auctions"
                >
                    <div className="container">
                        <div
                            className="row"
                            style={{display: "flex", justifyContent: "center"}}
                        >
                            <div className="col-xl-9 col-lg-8 col-md-12">
                                <div
                                    className="form-upload-profile post"
                                    style={{padding: "0"}}
                                >
                                    <h3 className="title-two fw-6">
                                        {Languages[language].create}
                                    </h3>
                                    <form action="#" className="form-profile">
                                        <div className="form-infor-profile flat-form">
                                            <div className="form-infor flex">
                                                <div className="info-title info-account">
                                                    <fieldset>
                                                        <p className="title-infor-account">
                                                            {Languages[language].seed}
                                                        </p>
                                                        <input
                                                            onChange={(e) => {
                                                                setSeed(e.target.value);
                                                            }}
                                                            type="number"
                                                            placeholder="Seed"
                                                            required
                                                        />
                                                    </fieldset>
                                                </div>
                                                <div className="info-stock info-social">
                                                    <p className="title-infor-account">
                                                        {Languages[language].price} : {quality.value} €{" "}
                                                    </p>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                padding: "10px",
                                                            }}
                                                            id="dropdown-basic"
                                                        >
                                                            {quality.title}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{margin: 0}}>
                                                            {dataDropdown.map((data) => (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setQuality(data);
                                                                        QualitySetter(data.title.toLowerCase());
                                                                    }}
                                                                    key={data.id}
                                                                    to="#"
                                                                >
                                                                    {data.description}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <fieldset className="message">
                                                <p className="title-infor-account">
                                                    {Languages[language].positive_top_text}
                                                </p>
                                                <textarea
                                                    onChange={(e) => {
                                                        setPositivePrompt(e.target.value);
                                                    }}
                                                    tabIndex="4"
                                                    rows="3"
                                                    required
                                                />
                                            </fieldset>
                                            <fieldset className="message">
                                                <p className="title-infor-account">
                                                    {Languages[language].avoid_top_text}
                                                </p>
                                                <textarea
                                                    onChange={(e) => {
                                                        setNegativePrompt(e.target.value);
                                                    }}
                                                    tabIndex="4"
                                                    rows="3"
                                                    required
                                                />
                                            </fieldset>
                                        </div>

                                        <h3 className="title-three fw-6">
                                            {Languages[language].artist_top_text}
                                        </h3>
                                        <p style={{marginBottom: "20px"}} className="text-tag">
                                            {Languages[language].artist_bottom_text}
                                        </p>
                                        <div className="flat-tabs tab-create-item flat-form">
                                            <Tabs>
                                                <TabList className="menu-tab tab-title flex-two">
                                                    <Tab
                                                        onClick={() => {
                                                            setArtist("Euler a");
                                                        }}
                                                    >
                                                        <span className="inner fw-6 font">Artist 1</span>
                                                    </Tab>
                                                    <Tab
                                                        onClick={() => {
                                                            setArtist("Euler");
                                                        }}
                                                    >
                                                        <span className="inner fw-6 font">Artist 2</span>
                                                    </Tab>
                                                    <Tab
                                                        onClick={() => {
                                                            setArtist("DPM2 a");
                                                        }}
                                                    >
                                                        <span className="inner fw-6 font">Artist 3</span>
                                                    </Tab>
                                                </TabList>
                                            </Tabs>
                                            {quality.value === "custom" && (
                                                <>
                                                    <div className="range_slider_wrapper">
                                                        <p style={{
                                                            alignSelf: 'center',
                                                            marginTop: '5px',
                                                            maxWidth: '550px'
                                                        }}>
                                                            {Languages[language].size_bottom_text}
                                                        </p>
                                                        <span>
                              {Languages[language].width}
                            </span>

                                                        <RangeSlider
                                                            steps={dimensions}
                                                            indexOfDefault={dimensions.findIndex(
                                                                (i) => i === 512
                                                            )}
                                                            callback={(value) => {
                                                                setWidth(value.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="range_slider_wrapper">
                            <span className="input_name">
                              {Languages[language].height}
                            </span>{" "}
                                                        <RangeSlider
                                                            steps={dimensions}
                                                            indexOfDefault={dimensions.findIndex(
                                                                (i) => i === 512
                                                            )}
                                                            callback={(value) => {
                                                                setHeight(value.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="range_slider_wrapper">
                            <span className="input_name">
                              {Languages[language].high_quality}
                            </span>
                                                        <p style={{
                                                            alignSelf: 'center',
                                                            marginTop: '5px',
                                                            maxWidth: '550px'
                                                        }}>
                                                            {Languages[language].high_bottom_text}
                                                        </p>
                                                        <RangeSlider
                                                            steps={hugeQualityArray}
                                                            indexOfDefault={hugeQualityArray.findIndex(
                                                                (i) => i === 0
                                                            )}
                                                            callback={(value) => {
                                                                setDenoise(value.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="range_slider_wrapper">
                            <span className="input_name">
                              {Languages[language].steps}
                            </span>
                                                        <p style={{
                                                            alignSelf: 'center',
                                                            marginTop: '5px',
                                                            maxWidth: '550px'
                                                        }}>
                                                            {Languages[language].steps_bottom_text}
                                                        </p>
                                                        <RangeSlider
                                                            steps={steps}
                                                            indexOfDefault={steps.findIndex((i) => i === 20)}
                                                            callback={(value) => {
                                                                setStepsValue(value.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="range_slider_wrapper">
                                                        <span className="input_name">Cfg scale</span>
                                                        <p style={{
                                                            alignSelf: 'center',
                                                            marginTop: '5px',
                                                            maxWidth: '550px'
                                                        }}>
                                                            {Languages[language].artist_freedom_bottom_text}
                                                        </p>
                                                        <RangeSlider
                                                            steps={CFG}
                                                            indexOfDefault={CFG.findIndex((i) => i === 7)}
                                                            callback={(value) => {
                                                                setCfg(value.value);
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                padding: "15px",
                                                display: "flex",
                                                textAlign: "center",
                                                placeContent: "center",
                                            }}
                                        >
                                            <h2 style={{color: "red"}}>{error}</h2>
                                        </div>
                                        <div className="create-form-bottom">
                                            <div className="check-terms">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        setCheck(e.target.checked);
                                                    }}
                                                    id="terms"
                                                    name="terms"
                                                />
                                                <label htmlFor="terms">
                                                    I agree to the{" "}
                                                    <a href="terms">Terms and Contitions</a>{" "}
                                                </label>
                                            </div>
                                            <div className="pay">
                        <span
                            onClick={() => {
                                if (quality.title.toLowerCase() === "custom") {
                                    setPrice(
                                        calculatePrice(quality.title.toLowerCase())
                                    );
                                }
                                sendJobWithPayments();
                            }}
                        >
                          PAY{" "}
                            {calculatePriceInCurrency(
                                calculatePrice(quality.title.toLowerCase())
                            )}{" "}
                        </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <CardModal show={modalShow} onHide={() => setModalShow(false)}/>
            {/*{<Flathelp/>}*/}
            {<Footer data={dataFooter}/>}
            {<Bottom classNameection="widget-social-footer"/>}
        </div>
    );
}

export default Create;
