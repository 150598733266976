import React , {useState, useContext} from 'react';
import '../scss/components/section.scss';
import {Languages} from "../languages/languages";

import dataFaq from '../assets/fake-data/data-faq';
import { Accordion } from 'react-bootstrap-accordion';
import {DataContext} from "../context/dataContext";

function Faq(props) {
    const {language} = useContext(DataContext);


    const [dataTab] = useState(
        [
            {
                id: 1,
                title: "Nufti General Faq",
            },
            {
                id: 2,
                title: "Nufti Help Faq",
            },
            {
                id: 3,
                title: "Nufti Blog Faq",
            },
        ]
    )

    return (

            <div className="tf-section flat-faq flat-create-item">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-faq">
                                <div className="flat-tabs tab-create-item flat-form flex-one">

                                    <div className="content-inner flat-accordion">
                                        {
                                            Languages[language].FAQ.map(item => (
                                                <Accordion key={item.id} title={item.title} show={item.show}>
                                                <div style={{
                                                    padding: '10px 0',
                                                    fontSize: '14px',
                                                    lineHeight: '1.5',

                                                    fontWeight: '400',

                                                }} className="toggle-content section-desc">{item.content}</div>
                                                </Accordion>
                                            ))
                                        }                                                   
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        </div>
    );
}

export default Faq;