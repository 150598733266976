import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../../button/button-st1';
import { DataContext } from "../../../context/dataContext";
import RobotArm from '../../../assets/images/mark/mark-slider.png';
import brush from '../../../assets/images/mark/brush.png';
Banner.propTypes = {
    item: PropTypes.object,
};


function Banner(props) {
    const { item } = props;
    const { user, language } = useContext(DataContext)

    return (
        <div className="themes-container">
            <div className="wrap-heading flat-slider flex j-content-between">
                <div className="content">
                    <h1 className="heading">{item.title[language]}{item.title3[language]} <span className="tf-text style s1">{item.title4}</span>  </h1>
                    <p className="sub-heading">{item.subtitle}</p>
                    <div className="flat-bt-slider flex style2">
                        {!!user ? <Button title={
                            language === 'fr' && 'Créez des images étonnantes' ||
                            language === 'en' && 'Create Amazing Pictures' ||
                            language === 'ro' && 'Creați imagini uimitoare'
                        } addclass='sc-button style-1 fl-button'
                            path='/create' /> : <Button title={
                                language === 'fr' && 'Créez des images étonnantes' ||
                                language === 'en' && 'Create Amazing Pictures' ||
                                language === 'ro' && 'Creați imagini uimitoare'
                            } addclass='sc-button style-1 fl-button'
                                path='/login' />
                        }
                    </div>
                </div>
                <div className="image">
                    <img className="mark-slider-01 animate-up" src={RobotArm} alt="" />
                    <img className="absolute mark-slider-02 animate-zoom" src={brush} alt="" />

                </div>
            </div>
        </div>
    );
}

export default Banner;