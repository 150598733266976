import React, {useState, useContext, useEffect} from 'react';
import {Languages} from "../languages/languages";
import {Navigate} from "react-router-dom";
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import {DataContext} from "../context/dataContext";
import {updateProfile} from "../Functions";
import {country} from "../helper/country";

function EditProfile(props) {
    const {user, setUser, language} = useContext(DataContext);
    const [info, setInfo] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            setInfo('');
        }, 3000);
        return () => clearTimeout(timer);
    }, [info]);

    const [newProfile, setNewProfile] = useState({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone: user?.phone,
        meta: {
            address: user?.meta?.address,
            address2: user?.meta?.address2,
            city: user?.meta?.city,
            state: user?.meta?.state,
            zip: user?.meta?.zip,
            country: user?.meta?.country,
        }

    });
    if (!user) return <Navigate to="/login"/>

    const handleChange = (e) => {
        e.preventDefault()
        updateProfile("https://api.artifusion.shop/profile", newProfile, user).then((res) => {
            setUser(res?.data)
            localStorage.setItem('userCredentials', JSON.stringify(res?.data));
            setInfo("Profile updated successfully")
        }).catch((err) => {
            setInfo("Profile update failed")
        })
    }

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header/>}
            <div style={{marginTop: '50px'}}
                 className="tf-section flat-explore flat-edit-profile flat-auctions-details ">
                <div style={{minHeight:'80vh'}} className="container">
                    <div style={{placeContent: 'center'}} className="row">
                        <div className="col-xl-9 col-lg-8">
                            <div className="form-upload-profile post">
                                <h3 className="title-two fw-6">Update Your Information</h3>
                                <form action="#" className="form-profile">
                                    <div className="form-infor-profile">
                                        <div className="form-infor flex flat-form">
                                            <div className="info-account">
                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].first_name}</p>
                                                    <input type="text" defaultValue={user?.first_name}
                                                           onChange={(e) => setNewProfile({
                                                               ...newProfile,
                                                               first_name: e.target.value
                                                           })}
                                                           placeholder="Enter Your Name" required/>
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].last_name}</p>
                                                    <input type="text" defaultValue={user?.last_name}
                                                           onChange={(e) => setNewProfile({
                                                               ...newProfile,
                                                               last_name: e.target.value
                                                           })}
                                                           placeholder="Enter Your Name" required/>
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].email}</p>
                                                    <input type="text" value={user?.email}
                                                           readOnly={true}
                                                           placeholder="info@gmail.com"/>
                                                </fieldset>
                                                {/*<fieldset>*/}
                                                {/*    <p className="title-infor-account">Password</p>*/}
                                                {/*    <input type="password" defaultValue={user?.first_name} placeholder="****************" required />*/}
                                                {/*</fieldset>*/}
                                            </div>
                                            <div className="info-social">
                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].phone}</p>
                                                    <input type="number"
                                                           defaultValue={user?.phone && Number(user?.phone)}
                                                           onChange={(e) => setNewProfile({
                                                               ...newProfile,
                                                               phone: e.target.value
                                                           })}
                                                           placeholder="+55 12333 444 55" name="phone" required/>
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].address}</p>
                                                    <input type="text" defaultValue={user?.meta?.address}
                                                           onChange={(e) => setNewProfile({
                                                               ...newProfile,
                                                               meta: {...newProfile.meta, address: e.target.value}
                                                           })}
                                                           placeholder="Your Address " required/>
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">Address2</p>
                                                    <input type="text" defaultValue={user?.meta?.address2}
                                                           onChange={(e) => setNewProfile({
                                                               ...newProfile,
                                                               meta: {...newProfile.meta, address2: e.target.value}
                                                           })}
                                                           placeholder="Your Address "/>
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].city}</p>
                                                    <input type="text" defaultValue={user?.meta?.city}
                                                           onChange={(e) => setNewProfile({
                                                               ...newProfile,
                                                               meta: {...newProfile.meta, city: e.target.value}
                                                           })}
                                                           placeholder="City" required/>
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].country}</p>
                                                <select
                                                    defaultValue={country[user?.meta?.country] ?? country['US']}
                                                    style={{width: '100%',
                                                        paddingTop:'15px',
                                                        paddingLeft:'15px',
                                                        paddingBottom:'15px',
                                                        backgroundColor:'#FFFFFF08',
                                                        border:'1px solid #ced4da24',
                                                        color:'#9c9aa5',
                                                        fontSize:'13px',
                                                        fontFamily:'Inter'
                                                }} onChange={(e) => {
                                                    setNewProfile({...newProfile, meta : {...newProfile.meta, country:Object.entries(country).filter((x) => x[1] === e.target.value)[0][0]}})
                                                }}
                                                    className="form-control mr-0">
                                                    {Object.values(country).map((x, key) => {
                                                        return <option key={key} value={x}>{x}</option>
                                                    })}
                                                </select>
                                                </fieldset>

                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].state}</p>
                                                    <input type="text" defaultValue={user?.meta?.state}
                                                           onChange={(e) => setNewProfile({
                                                               ...newProfile,
                                                               meta: {...newProfile.meta, state: e.target.value}
                                                           })}
                                                           placeholder="State" required/>
                                                </fieldset>
                                                <fieldset>
                                                    <p className="title-infor-account">{Languages[language].zip}</p>
                                                    <input type="text" defaultValue={user?.meta?.zip} placeholder="Zip"
                                                           onChange={(e) => setNewProfile({
                                                               ...newProfile,
                                                               meta: {...newProfile.meta, zip: e.target.value}
                                                           })}
                                                           required/>
                                                </fieldset>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{display:'flex',justifyContent:'center', margin:'15px',color:'#4bff4b'}}>
                                        {info.length > 0 && <h3>
                                            {info}
                                        </h3>}
                                    </div>
                                    <button className="tf-button-submit mg-t-15" type="submit"
                                            onClick={(e) => {
                                                handleChange(e)
                                            }
                                            }
                                    >
                                        {Languages[language].save}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default EditProfile;