import React, { useContext } from 'react'

import PopClose from '../components/PopClose';
import ButtonOne from '../../button/ButtonOne';
import { Languages } from '../../../languages/languages';
import { DataContext } from '../../../context/dataContext';
import { POP_SECTIONS } from '../constants';

const Base = ({ setPopPage, setShown }) => {
    const { language } = useContext(DataContext);
  
    return (
      <>
        <PopClose setShown={setShown} />
        <h5 style={{
          color:'black',
          fontWeight: 500,
          fontSize: '1.5rem',
          marginBottom: '15px'
      
      }} >{Languages[language].pop_title}</h5>
        <p className="mb-4 text-center">{Languages[language].pop_text}</p>
        <ButtonOne
          className="w-100 text-center fs-6 style-2 p-2"
          _callback={() => {
            setPopPage({
                page: POP_SECTIONS.qna
            });
          }}
          text={Languages[language].pop_button_text}
          path={false}
        />
      </>
    );
  };

export default Base