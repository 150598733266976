import img1 from '../images/box-item/conlection-17.jpg';
import img2 from '../images/box-item/conlection-5.jpg';
import img3 from '../images/box-item/conlection-9.jpg';
import img4 from '../images/box-item/conlection-1.jpg';
import img5 from '../images/box-item/conlection-5.jpg';

import imgconlection1 from '../images/box-item/conlection-2.jpg';
import imgconlection2 from '../images/box-item/conlection-3.jpg';
import imgconlection3 from '../images/box-item/conlection-4.jpg';
import imgconlection4 from '../images/box-item/conlection-6.jpg';
import imgconlection5 from '../images/box-item/conlection-7.jpg';
import imgconlection6 from '../images/box-item/conlection-8.jpg';
import imgconlection7 from '../images/box-item/conlection-10.jpg';
import imgconlection8 from '../images/box-item/conlection-11.jpg';
import imgconlection9 from '../images/box-item/conlection-12.jpg';

import imgavt from '../images/avatar/avt-01.png';

import icon1 from '../images/icon/author-check.svg';
import icon2 from '../images/icon/icon-nft.svg';


const dataCollection = [
    {
        id: 1,
        img: 'https://api.artfactory.live/image/1669362935749',
        imgconlection1 : "https://api.artfactory.live/image/1669402588776",
        imgconlection2 : 'https://api.artfactory.live/image/1669364880275',
        imgconlection3 : 'https://api.artfactory.live/image/1669363132702',
        title: 'Axtronic Electronics VS-10',

    },
    {
        id: 2,
        img: 'https://api.artfactory.live/image/1669402655402',
        imgconlection1 : 'https://api.artfactory.live/image/1669363085907',
        imgconlection2 : 'https://api.artfactory.live/image/1669362935760',
        imgconlection3 : 'https://api.artfactory.live/image/1669220561001',
        title: 'Axtronic Electronics VS-10',
        imgavt: imgavt,
        author: 'Themesflat',
        date : '@ 3d Models',
        countitem : '805 Items',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 3,
        img: 'https://api.createart.live/image/1670250474376',
        imgconlection1 : 'https://api.createart.live/image/1669219771676',
        imgconlection2 : 'https://api.artfactory.live/image/1669444719951',
        imgconlection3 : 'https://api.artfactory.live/image/1669220612260',
        title: 'Axtronic Electronics VS-10',
        imgavt: imgavt,
        author: 'Themesflat',
        date : '@ 3d Models',
        countitem : '805 Items',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 4,
        img: 'https://api.createart.live/image/1671546015732',
        imgconlection1 : 'https://api.createart.live/image/1670420546855',
        imgconlection2 : 'https://api.createart.live/image/1670317262631',
        imgconlection3 : 'https://api.artfactory.live/image/1669219771676',
        title: 'Axtronic Electronics VS-10',
        imgavt: imgavt,
        author: 'Themesflat',
        date : '@ 3d Models',
        countitem : '805 Items',
        icon1 : icon1,
        icon2 : icon2,
    },
    {
        id: 5,
        img: 'https://api.createart.live/image/1669363085907',
        imgconlection1 : 'https://api.createart.live/image/1670420768138',
        imgconlection2 : 'https://api.createart.live/image/1669363085909',
        imgconlection3 : 'https://api.createart.live/image/1670250474376',
        title: 'Axtronic Electronics VS-10',
        imgavt: imgavt,
        author: 'Themesflat',
        date : '@ 3d Models',
        countitem : '805 Items',
        icon1 : icon1,
        icon2 : icon2,
    },

]

export default dataCollection;