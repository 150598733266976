import img1 from '../images/mark/mark-slider-3.png';
import img2 from '../images/mark/mark-header-07.png';
import img3 from '../images/mark/mark-slider-1.jpg';
import img4 from '../images/mark/mark-slider-2.jpg';

import img5 from '../images/mark/mark2-slider-home2.png';
import img6 from '../images/mark/mark1-slider-home2.png';

import img7 from '../images/mark/mark2-slider-home3.png';
import img8 from '../images/mark/mark1-slider-home3.png';

const dataBanner = [
    {
        id: 1,
        classAction: 'flat-slider flex',
        title: {
            en: 'Create your own picture',
            fr: 'Créez votre propre image',
            ro: 'Creazâ-ți propria imagine',
        },

        title3: {
            en: ' in a few clicks',
            fr: ' en quelques clics',
            ro: ' în câteva click-uri',
        },
        title4: '',
        subtitle: '',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },
    {
        id: 3,
        classAction: 'flat-slider flex',
        title: {
            en: 'Create your own picture',
            fr: 'Créez votre propre image',
            ro: 'Creazâ-ti propria imagine',
        },

        title3: {
            en: ' in a few clicks',
            fr: ' en quelques clics',
            ro: ' în câteva click-uri',
        },
        title4: '',
        subtitle: '',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    }, {
        id: 3,
        classAction: 'flat-slider flex',
        title: {
            en: 'Create your own picture',
            fr: 'Créez votre propre image',
            ro: 'Creează ți propria imagine',
        },

        title3: {
            en: ' in a few clicks',
            fr: ' en quelques clics',
            ro: ' în câteva clicuri',
        },
        
        title4: '',
        subtitle: '',
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
    },

]

export default dataBanner;