import React, {useEffect, useState, Fragment} from 'react';
import '../scss/components/section.scss';
import {useNavigate} from 'react-router-dom';
import Header from '.././components/header/header.jsx';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import {DataContext} from "../context/dataContext";



function Inspiration() {
    const navigate = useNavigate();
    const {user, language} = React.useContext(DataContext)






    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header/>}
      <Fragment>
                <div style={{height: '100%', minHeight: '800px', marginTop: '80px'}}
                     className="tf-section flat-auctions-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="wrap-img">
                                    <div className="img-detail">
                                        <div className="media">
                                            <img style={{maxWidth: '500px', maxHeight: '700px', objectFit: 'cover'}}
                                                 src={`https://api.artifusion.shop/image/1669364880275`}
                                                 alt="createart"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{alignSelf: 'center'}}>
                                <div className="post mtop-7">


                                    <div className="box-price flat-price flex">
                                        <div className="price flex">
                                        </div>

                                    </div>
                                    <p className="texts">totem animal totem aztek greeble tribal style fanart ornate fantasy heartstone ankama gta5 cover style official behance hd artstation by Jesper Ejsing, by RHADS, Makoto Shinkai and Lois van baarle, ilya kuvshinov, rossdraws totem color pastel vibrant radiating a glowing aura intricate, elegant, sharp focus, illustration, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece</p>
                                    <div className="information flex">
                                        <div className="column column-1">
                                            <h4 className="title-1 fw-5 text-color-3">Artist: <span
                                                className="">Artis1</span>
                                            </h4>
                                            <h4 className="fw-5 text-color-3">Size: <span
                                                className="">512 X 896</span>
                                            </h4>
                                        </div>
                                        <div className="column column-2">
                                            <h4 className="title-1 fw-5 text-color-3">steps: <span
                                                className="">50</span>
                                            </h4>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'30px', flexDirection:'row-reverse'}}>
                            <div className="col-lg-6 col-md-12">
                                <div className="wrap-img">
                                    <div className="img-detail">
                                        <div className="media">
                                            <img style={{maxWidth: '500px', maxHeight: '700px', objectFit: 'cover'}}
                                                 src={`https://api.artifusion.shop/image/1670250474376`}
                                                 alt="createart"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{alignSelf: 'center'}}>
                                <div className="post mtop-7">


                                    <div className="box-price flat-price flex">
                                        <div className="price flex">
                                        </div>

                                    </div>
                                    <p className="texts">various animals playing poker</p>
                                    <div className="information flex">
                                        <div className="column column-1">
                                            <h4 className="title-1 fw-5 text-color-3">Artist: <span
                                                className="">Artis2</span>
                                            </h4>
                                            <h4 className="fw-5 text-color-3">Size: <span
                                                className="">512 X 512</span>
                                            </h4>
                                        </div>
                                        <div className="column column-2">
                                            <h4 className="title-1 fw-5 text-color-3">steps: <span
                                                className="">20</span>
                                            </h4>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col-lg-6 col-md-12">
                                <div className="wrap-img">
                                    <div className="img-detail">
                                        <div className="media">
                                            <img style={{maxWidth: '500px', maxHeight: '700px', objectFit: 'cover'}}
                                                 src={`https://api.artifusion.shop/image/1669362935760`}
                                                 alt="createart"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{alignSelf: 'center'}}>
                                <div className="post mtop-7">


                                    <div className="box-price flat-price flex">
                                        <div className="price flex">
                                        </div>

                                    </div>
                                    <p className="texts">Black and white photo of a perfect body nude woman wearing an owl mask, [full view], high contrast, high details, by Stéphane de Bourgies, studio quality, dramatic lighting, 8 k, hi res</p>
                                    <div className="information flex">
                                        <div className="column column-1">
                                            <h4 className="title-1 fw-5 text-color-3">Artist: <span
                                                className="">Artis3</span>
                                            </h4>
                                            <h4 className="fw-5 text-color-3">Size: <span
                                                className="">512 X 704</span>
                                            </h4>
                                        </div>
                                        <div className="column column-2">
                                            <h4 className="title-1 fw-5 text-color-3">steps: <span
                                                className="">50</span>
                                            </h4>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>


            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Inspiration;