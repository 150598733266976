import React, {useContext, useEffect, useState} from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import Flathelp from '../components/flat-help';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import {Link} from 'react-router-dom';
import {loginAction, GetAllJobs, GetCurrentRate} from "../Functions";
import {DataContext} from "../context/dataContext";
import {useNavigate} from "react-router-dom";

function Login(props) {
    const {setUser, language} = useContext(DataContext)
    const navigate = useNavigate();
    const [loginCredentials, setLoginCredentials] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState('');
 //clear error message after 5 seconds in useEffect
    useEffect(() => {
        const timer = setTimeout(() => {
            setError('')
        }, 5000);
        return () => clearTimeout(timer);
    }, [error]);




    const handleLogin = async (e) => {
        const {email, password} = loginCredentials;
        if(email === '' || password === '') return setError('Please fill in all fields')
        if(email.length < 3 || password.length < 3) return setError('Please fill in all fields')
        e.preventDefault();
        await loginAction("https://api.artifusion.shop/login", email, password).then(res => {
            if (!res?.data) return;
            setUser(res?.data)

            localStorage.setItem('userCredentials', JSON.stringify(res?.data));
            Promise.all([
                GetCurrentRate('https://api.artifusion.shop/rates'),
                GetAllJobs(res?.data)
            ])
                .then(() => navigate('/gallery'))
        }).catch(c => {
            setError(c?.response?.data?.error.message ?? 'Something went wrong')

        });
    }


    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header/>}

            <div style={{marginTop: '50px'}} className="tf-section flat-login flat-auctions-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-login">
                                <div className="box-login post">
                                    <img style={{width: '100px'}} src={require('../assets/images/mark/login3.png')}
                                         alt="createart"
                                         className="absolute mark-page3"/>
                                    <img style={{width: '60px'}} src={require('../assets/images/mark/login1.png')}
                                         alt="createart"
                                         className="absolute mark-login1"/>
                                    <img style={{width: '80px'}} src={require('../assets/images/mark/login2.png')}
                                         alt="createart"
                                         className="absolute mark-login2 animate-rotate"/>

                                    <img src={require('../assets/images/backgroup-secsion/bg-login.png')}
                                         alt="createart" className="absolute mark-login"/>

                                    <div className="heading-login">
                                        <h2 className="fw-5">Login</h2>
                                        <div className="flex"><p>Dont’t have an account yet? </p><Link to="/register"
                                                                                                       className="text-p text-color-3 fw-6">Register</Link>
                                        </div>
                                    </div>
                                    <form action="#" id="contactform">
                                        <div className="form-login flat-form flex-one">
                                            <div className="info-login">
                                                <fieldset>
                                                    <p className="title-infor-account">Email</p>
                                                    <input onChange={(e) => setLoginCredentials({
                                                        ...loginCredentials,
                                                        email: e.target.value
                                                    })} type="email" name="email" id="email" placeholder='email'
                                                           aria-required="true" required/>
                                                </fieldset>
                                                <fieldset className="style-pass">
                                                    <p className="title-infor-account">Password</p>
                                                    <input onChange={(e) => setLoginCredentials({
                                                        ...loginCredentials,
                                                        password: e.target.value
                                                    })}
                                                           type="password" name="password" id="password"
                                                           placeholder="*********************************"
                                                           aria-required="true" required/>
                                                </fieldset>
                                                <div className="row-form style-1 flex-two">
                                                    <label className="flex align">
                                                        <input type="checkbox"/>
                                                        <span className="btn-checkbox flex-two"></span>
                                                        <span className="text-p text-color-7">Remember Me</span>
                                                    </label>
                                                </div>
                                                {error.length > 0 && <div className="error-message"
                                                style={{display: 'flex', justifyContent: 'center', color: 'red',fontSize:'18px',marginBottom:'15px'}}>{error}</div>}

                                                <button onClick={(e) => {
                                                    handleLogin(e)
                                                }} className="submit button-login">Login
                                                </button>

                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<Flathelp/>}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Login;