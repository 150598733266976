import React,{useContext} from 'react';
import {DataContext} from "../../../context/dataContext";
import PropTypes from 'prop-types';
Friendly.propTypes = {
    item: PropTypes.object,
};


function Friendly(props) {
    const {item} = props;
    const {language} = useContext(DataContext)
    return (
        <li>
            <img className="img-icon" src={item.img} style={{width:'70px', height:'70px'}} alt="" />
            <div className={`title-friendly fs-24 ${item.classAction}`}>{item.title[language]}</div>
        </li>
    );
}

export default Friendly;