import React from "react";
import { qnas } from "../components/pop-thingy/helpers/getQnaTranslations";

export const Languages = {
    en: {
        //buttons
        high_quality: "High Quality",
        login: 'Login',
        register: 'Register',
        logout: 'Sign out',
        profile: 'Profile',
        gallery: 'Gallery',
        create: 'Create',
        inspiration: 'Inspiration',
        make_art: 'Make Art',
        my_gallery: 'My Gallery',
        generate_picture: 'Generate Picture',
        submit: 'Submit',
        refresh: 'Refresh',
        download: 'Download',
        go_to_customize: 'Go to Customize',
        save: 'Save',
        delete: 'Delete',
        go_to_create: 'Go to Create',
        terms_and_conditions: 'Terms and Conditions',
        privacy_policy: 'Privacy Policy',
        //satisfied pop up thingy
        pop_title: "Not feeling satisfied?",
        pop_text: "Let us solve your problem.",
        pop_button_text: "Click here!",

        pop_qna_close_text: "Close",
        pop_something_went_wrong: "Oops, something went wrong!",
        // qna: {
        //     base: {
        //         question: "What happened?",
        //         answers: {
        //             speed: {
        //               text: "Speed",
        //               redirect: "speed_whats_wrong",
        //             },
        //             payment: {
        //               text: "Payment",
        //             },
        //             quality: {
        //               text: "Quality",
        //             },
        //         },
        //     },


        //     // speed related
        //     speed_whats_wrong: {
        //         question: "What's wront with the speed?",

        //         answers: {
        //           speed_too_slow: {
        //             text: "Too slow",
        //             redirect: "speed_process_rerun",
        //           },
        //           speed_not_loading: {
        //             text: "Stuck at the end.",
        //             redirect: "refund",
        //           },
        //           speed_stuck: {
        //             text: "Not working.",
        //           },
        //         },
        //       },
        //       speed_process_rerun: {
        //         question: "Did you re-run the process?",

        //         answers: {
        //           speed_too_slow_yes: {
        //             text: "Yes",
        //             redirect: "refund",
        //           },
        //           speed_too_slow_no: {
        //             text: "No",
        //           },
        //         },
        //       },
        //       speed_process_rerun_did_work: {
        //         question: "Did re-running the process work?",

        //         answers: {
        //             speed_process_rerun_did_work_yes: {
        //             text: "Yes",
        //           },
        //           speed_process_rerun_did_work_no: {
        //             text: "No",
        //             redirect: "refund",
        //           },
        //         },
        //       },


        //       //base last refund
        //       refund: {
        //         question: "Sorry to hear that it didn't work.",

        //         answers: {
        //           refund_refund: {
        //             text: "I want a refund.",
        //             redirect: "refund",
        //           },
        //           refund_free_token: {
        //             text: "I want a free token.",
        //           },
        //         },
        //       },
        // },

        qna: qnas, // imported from "getAllQnaTranslations", because its 1:1 the same

        pop_refund_title: "Which image made you make this decision?",
        pop_refund_button: "Accept & Send",
        pop_refund_button_disabled: "Select atleast one!",

        pop_redirect_close: "Alright. Hope you can solve it.",
        pop_redirect_redirecting: "Redirecting...",


        //profile
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        password: 'Password',
        confirm_password: 'Confirm Password',
        phone: 'Phone',
        address: 'Address',
        city: 'City',
        state: 'State',
        zip: 'Zip',
        country: 'Country',
        county: 'County',

        //inspiration
        steps: 'Steps',
        seed: 'Seed',
        width: 'Width',
        height: 'Height',
        artist: 'Artist',
        template: 'Template',
        input: 'Input',
        price: 'Price',
        create_amazing_pictures: 'Create Amazing Pictures',

        //footer action div
        footer_action_div_top_text: 'Create Your New Art',
        footer_action_div_bottom_text: 'Generate something amazing',
        //create page
        positive_top_text: "Tell us about your masterpiece",
        positive_bottom_text: <>Enter some text for the artist to create your image, it works best if you think of your ideas as captions for the result you would like.<br></br></>,
        avoid_top_text: 'Is there something you want to avoid',
        avoid_bottom_text: <>You can add things that you don't want to see in your image here. as above it's best to use captions of the items to avoid.</>,
        artist_top_text: 'Your artist',
        artist_bottom_text: <>There are currently 3 artists to choose from. These artists will make different decisions each step of the way to create your magic. you can see some examples above; for beginners we suggest artist 1</>,
        size_top_text: 'What size would you like your works',
        size_bottom_text: 'You can select from 64px to 2048px for height or width.',
        high_top_text: 'Going BIG?',
        high_bottom_text: 'The artists work best around 512px and if going past this point it should be done in a single direction or with the assistance of this high-quality setting that will split the work into 512x512 jobs and merge them.',
        high_input_label: 'High Quality',
        steps_top_text: 'Steps',
        steps_bottom_text: 'Each step allows the artist to improve your image, around 20 steps will get you a basic image, 50 or so would be better for higher quality.',
        steps_input_label: 'Sampling steps',
        artist_freedom_top_text: 'Artist Freedom',
        artist_freedom_bottom_text: <>Here you can choose the weight of your input to the piece. A basic setting of around 7 yields results that both represent your ideas and have some freedom of expression. 1 will only contain a hint of your input and 30 will most likly over bake the ideas.
            <br></br> Suggested: 5-15</>,
        artist_freedom_input_label: 'CFG scale',
        //create page/quality
        starting_number: 'Starting number',
        starting_number_bottom_text: 'Optional',
        draft_quality: 'Draft quality',
        draft_quality_bottom_text: 'Most cost-effective',
        basic_quality: 'Basic quality',
        basic_quality_bottom_text: 'More refined',
        standard_quality: 'Standard quality',
        standard_quality_bottom_text: 'Double the size',
        premium_quality: 'Premium quality',
        premium_quality_bottom_text: 'More refined',
        supreme_quality: 'Supreme quality',
        supreme_quality_bottom_text: 'Huge',
        custom: 'Custom',
        custom_bottom_text: 'Go crazy',
        //home page
        home_page_first_slider_top_text: 'Make something amazing in minutes',
        home_page_first_slider_bottom_text: 'The machine does all the work',
        home_page_second_slider_top_text: 'Create pictures almost instantly',
        home_page_second_slider_middle_text: 'you supply the concepts for the art and have access to additional advanced options if you want to go crazy',
        home_page_second_slider_bottom_text: 'You can download and have full usage rights to your artwork, along with all the information needed to recreate or remix your creation. ',
        idea: 'Idea',
        write: 'Write',
        wait: 'Wait',
        art: 'Art',
        join: 'Join',
        join_top_text: 'Join and create amazing images',
        become: <>Become a Artfactory <br></br> User Now </>,
        register_text: "Register an account with us and get amazing pictures",
        go_to_create_page: 'Go to Create Page',
        go_to_create_page_text: 'Go to create page and create your own images',
        write_your_magic: 'Write your magic',
        write_your_magic_text: 'Write your magic and the machine will do the rest',
        wait_for_the_result: 'Wait for the result',
        wait_for_the_result_text: 'Wait for the results and enjoy your new images',

        FAQ_short_title: 'FAQ',
        FAQ_title: 'Frequently Asked Questions',
        FAQ: [
            {
                id: 1,
                title: 'WHAT IS AN ARTIST?',
                content: <>
                    <p>Each artist is an image generation system.</p>
                    <p>It combines previous ideas including:</p>
                    <ul>
                        <li>● Perception Engines which uses image augmentation and iteratively optimises images against an
                            ensemble of classifiers
                        </li>
                        <br></br>
                        <li>● CLIP guided GAN imagery from Ryan Murdoch and Katherine Crowson as well as modifictions such as
                            CLIPDraw from Kevin Frans
                        </li>
                        <br></br>
                        <li>● Useful ways of navigating latent space from Sampling Generative Networks</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                id: 2,
                title: 'WHY DOES IT COST MORE FOR LARGER IMAGES?',
                content: <>
                    <p>Every image is generated frame by frame and this consumes considerable resources.</p>
                    <ul>
                        <li>● Draft takes only a few seconds</li>
                        <li>● Supreme can take 30 minutes</li>
                        <br></br>
                    </ul>
                    <p>During this time we have the latest neural network hardware totally consumed by your artwork.</p>
                    <p>We are able to scale by purchasing more hardware and process more in parallel.</p>
                </>,
            },
            {
                id: 3,
                title: 'WHY CANT I HAVE A BIGGER IMAGE?',
                content: 'We are working on a solution to make even larger images, right now we are constrained by the amount of memory we can have in a single artist. We hope to add the addition of super-sampling to increase the size considerably.'
            },
        ]
    },
    //France
    fr: {
        //buttons
        high_quality: 'Haute qualité',
        login: 'Connexion',
        register: 'S\'inscrire',
        logout: 'Déconnexion',
        profile: 'Profil',
        gallery: 'Galerie',
        create: 'Créer',
        inspiration: 'Inspiration',
        make_art: 'Faire de l\'art',
        my_gallery: 'Ma galerie',
        generate_picture: 'Générer une image',
        submit: 'Soumettre',
        refresh: 'Rafraîchir',
        download: 'Télécharger',
        go_to_customize: 'Aller à Personnaliser',
        save: 'Économiser',
        delete: 'Effacer',
        go_to_create: 'Allez dans Créer',
        terms_and_conditions: 'Termes et conditions',
        privacy_policy: 'Politique de confidentialité',
        //profile
        first_name: 'Prénom',
        last_name: 'Nom de famille',
        email: 'E-mail',
        password: 'Passe',
        confirm_password: 'Confirmez le mot de passe',
        phone: 'Téléphoner',
        address: 'Adresse',
        city: 'Ville',
        state: 'Etat',
        zip: 'Zip ',
        country: 'Pays',
        county: 'Comté',
        //inspiration
        steps: 'Pas',
        seed: 'Planter',
        width: 'Largeur',
        height: 'Hauteur',
        artist: 'Artiste',
        template: 'Modèle',
        input: 'Saisir',
        price: 'Prix',
        create_amazing_pictures: 'Créez des images étonnantes',

        //satisfied pop up thingy
        pop_title: "Vous ne vous sentez pas satisfait(e) ?",
        pop_text: "Laissez-nous résoudre votre problème.",
        pop_button_text: "Cliquez ici !",

        pop_qna_close_text: "Fermer",
        pop_something_went_wrong: "Oups, quelque chose s'est mal passé!",
        qna: {
            base: {
                question: "Qu'est-il arrivé ?",
                answers: {
                    speed: {
                        text: "Vitesse",
                        redirect: "speed_whats_wrong"
                    },
                    payment: {
                        text: "Paiement"
                    },
                    quality: {
                        text: "Qualité"
                    }
                }
            },

            speed_whats_wrong: {
                question: "Qu'est-ce qui ne va pas avec la vitesse ?",
                answers: {
                    speed_too_slow: {
                        text: "Trop lent",
                    },
                    speed_not_loading: {
                        text: "Bloqué à la fin.",
                    },
                    speed_stuck: {
                        text: "Ne fonctionne pas.",
                    },
                },
            },

            payment_whats_wrong: {
                question: "Qu'est-ce qui ne va pas avec le paiement?",
                answers: {
                    payment_still_pending: {
                        text: "Déjà payé, mais ça indique toujours en attente...",
                    },
                    payment_charged_twice: {
                        text: "J'ai été débité deux fois.",
                    },
                }
            },
            payment_still_pending: {
                question: "Essayez de le générer à nouveau?",
                answers: {
                    payment_still_pending_regen: {
                        text: "Oui.",
                    },
                    payment_still_pending_refund: {
                        text: "Non, je veux un remboursement.",
                    },
                },
            },

            quality_whats_wrong: {
                question: "Qu'est-ce qui ne va pas avec la qualité?",
                answers: {
                    quality_bad: {
                        text: "La qualité de l'image est mauvaise.",
                    },
                    payment_charged_twice: {
                        text: "L'image n'est pas entièrement générée.",
                    },
                },
            },
            quality_bad: {
                question: "Essayez de le générer à nouveau?",
                answers: {
                    quality_bad_regen: {
                        text: "Oui.",
                    },
                    quality_bad_refund: {
                        text: "Non, je veux un remboursement.",
                    },
                },
            },

            refund: {
                question: "Désolé d'apprendre que cela n'a pas fonctionné.",
                answers: {
                    refund_refund: {
                        text: "Je souhaite un remboursement.",
                    },
                    refund_free_token: {
                        text: "Je souhaite un jeton gratuit."
                    }
                }
            }
        },

        pop_refund_title: "Quelle image vous a fait prendre cette décision?",
        pop_refund_button: "Accepter et Envoyer",
        pop_refund_button_disabled: "Sélectionnez au moins un!",

        pop_redirect_close: "D'accord. J'espère que vous pourrez le résoudre.",
        pop_redirect_redirecting: "Redirection en cours...",

        idea: 'Idée',
        write: 'Écrire',
        wait: 'Attendre',
        art: 'Art',
        //footer action div
        footer_action_div_top_text: 'Créez votre nouvel art',
        footer_action_div_bottom_text: 'Générez quelque chose d\'incroyable',
        //create page
        positive_top_text: "Parlez-nous de votre chef-d'œuvre",
        positive_bottom_text: <>Entrez du texte pour que l'artiste crée votre image, cela fonctionne mieux si vous considérez vos idées comme des légendes pour le résultat que vous souhaitez.<br></br></>,
        avoid_top_text: 'Y a-t-il quelque chose que vous voulez éviter',
        avoid_bottom_text: <>Vous pouvez ajouter ici des choses que vous ne voulez pas voir dans votre image. comme ci-dessus, il est préférable d'utiliser les légendes des éléments à éviter.</>,
        artist_top_text: 'Votre artiste',
        artist_bottom_text: <>Il y a actuellement 3 artistes parmi lesquels choisir. Ces artistes prendront des décisions différentes à chaque étape du processus pour créer votre magie. vous pouvez voir quelques exemples ci-dessus; pour les débutants, nous suggérons</>,
        size_top_text: 'Quelle taille souhaitez-vous pour vos oeuvres',
        size_bottom_text: 'Vous pouvez sélectionner de 64px à 2048px pour la hauteur ou la largeur.',
        high_top_text: 'Aller GRAND?',
        high_bottom_text: 'les artistes travaillent mieux autour de 512px et si vous dépassez ce point, cela devrait être fait dans une seule direction ou avec l\'aide de ce paramètre de haute qualité qui divisera le travail en 512x512 travaux et les fusionnera.',
        high_input_label: 'Haute qualité',
        steps_top_text: 'Pas',
        steps_bottom_text: 'chaque étape permet à l\'artiste d\'améliorer votre image, environ 20 étapes vous permettront d\'obtenir une image de base, une cinquantaine serait préférable pour une meilleure qualité.',
        steps_input_label: 'Étapes d\'échantillonnage',
        artist_freedom_top_text: 'Liberté de l\'artiste',
        artist_freedom_bottom_text: <>ici, vous pouvez choisir le poids de votre contribution à la pièce. Un réglage de base d'environ 7 donne des résultats qui représentent à la fois vos idées et une certaine liberté d'expression. 1 ne contiendra qu'un soupçon de votre contribution et 30 contiendra très probablement les idées.
            <br></br> Suggéré : 5-15</>,
        artist_freedom_input_label: 'CFG scale',
        //create page/quality
        starting_number: 'Numéro de départ',
        starting_number_bottom_text: 'Vous pouvez éventuellement spécifier un point de départ pour votre art. Cela permettra des ajustements futurs.',
        draft_quality: 'Qualité brouillon',
        draft_quality_bottom_text: 'Le plus rentable',
        basic_quality: 'Qualité de base',
        basic_quality_bottom_text: 'Plus raffiné',
        standard_quality: 'Qualité standard',
        standard_quality_bottom_text: 'Doubler la taille',
        premium_quality: 'Qualité supérieure',
        premium_quality_bottom_text: 'Plus raffiné',
        supreme_quality: 'Qualité suprême',
        supreme_quality_bottom_text: 'Énorme',
        custom: 'Personnalisé',
        custom_bottom_text: 'Devenir fou',
        //home page
        home_page_first_slider_top_text: 'Créez quelque chose d\'incroyable en quelques minutes',
        home_page_first_slider_bottom_text: 'La machine fait tout le travail',

        home_page_second_slider_top_text: 'Créez des images presque instantanément',
        home_page_second_slider_middle_text: 'vous fournissez les concepts de l\'art et avez accès à des options avancées supplémentaires si vous voulez devenir fou',
        home_page_second_slider_bottom_text: 'Vous pouvez télécharger et disposer de tous les droits d\'utilisation de votre œuvre, ainsi que de toutes les informations nécessaires pour recréer ou remixer votre création. ',

        join: 'Rejoindre',
        join_top_text: 'Rejoignez et créez des images étonnantes',
        become: <>Devenez un utilisateur d'Artfactory <br></br> maintenant</>,
        register_text: "Enregistrez un compte avec nous et obtenez de superbes photos",
        go_to_create_page: 'Aller à Créer une page',
        go_to_create_page_text: 'Accédez à la page de création et créez vos propres images',
        write_your_magic: 'Écrivez votre magie',
        write_your_magic_text: 'Écrivez votre magie et la machine fera le reste',
        wait_for_the_result: 'Attendez le résultat',
        wait_for_the_result_text: 'Attendez les résultats et profitez de vos nouvelles images',

        FAQ_short_title: 'FAQ',
        FAQ_title: 'Questions fréquemment posées',
        FAQ: [
            {
                id: 1,
                title: 'QU\'EST-CE QU\'UN ARTISTE?',
                content: <>
                    <p>Chaque artiste est un système de génération d'images.</p>
                    <p>Il combine des idées précédentes, notamment:</p>
                    <ul>
                        <li>● Perception Engines qui utilise l'augmentation d'image et optimise de manière itérative les images par rapport à un
                            ensemble de classificateurs
                        </li>
                        <br></br>
                        <li>● Imagerie GAN guidée par CLIP de Ryan Murdoch et Katherine Crowson ainsi que des modifications telles que
                            CLIPDraw de Kevin Frans
                        </li>
                        <br></br>
                        <li>● Moyens utiles de naviguer dans l'espace latent à partir des réseaux génératifs d'échantillonnage</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                id: 2,
                title: 'POURQUOI LES IMAGES PLUS GRANDES COÛTENT-ELLES PLUS GRANDES ??',
                content: <>
                    <p>Chaque image est générée image par image et cela consomme des ressources considérables.</p>
                    <ul>
                        <li>● Le brouillon ne prend que quelques secondes</li>
                        <li>● Suprême peut prendre 30 minutes</li>
                        <br></br>
                    </ul>
                    <p>Pendant ce temps, nous avons le dernier matériel de réseau neuronal totalement consommé par vos œuvres d'art.</p>
                    <p>Nous pouvons évoluer en achetant plus de matériel et en traitant davantage en parallèle.</p>
                </>,
            },
            {
                id: 3,
                title: 'POURQUOI JE NE PEUX PAS AVOIR UNE PLUS GRANDE IMAGE?',
                content: 'Nous travaillons sur une solution pour faire des images encore plus grandes, en ce moment nous sommes limités par la quantité de mémoire que nous pouvons avoir chez un seul artiste. Nous espérons ajouter l\'ajout de super-échantillonnage pour augmenter considérablement la taille.'
            },
        ]
    },
    ro: {
        //buttons
        high_quality: "Calitate Înaltă",
        login: 'Autentificare',
        register: 'Înregistrare',
        logout: 'Deconectare',
        profile: 'Profil',
        gallery: 'Galerie',
        create: 'Crează',
        inspiration: 'Inspirație',
        make_art: 'Fă Artă',
        my_gallery: 'Galeria Mea',
        generate_picture: 'Generează Imagine',
        submit: 'Trimite',
        refresh: 'Reîmprospătare',
        download: 'Descarcă',
        go_to_customize: 'Personalizează',
        save: 'Salvează',
        delete: 'Șterge',
        go_to_create: 'Mergi la Crează',
        terms_and_conditions: 'Termeni și Condiții',
        privacy_policy: 'Politica de Confidențialitate',
        //satisfied pop up thingy
        pop_title: "Nu ești satisfăcut?",
        pop_text: "Hai să rezolvăm problema ta.",
        pop_button_text: "Click aici!",

        pop_qna_close_text: "Închide",
        pop_something_went_wrong: "Oops, ceva nu a mers bine!",
        qna: {
          base: {
            question: "Ce s-a întâmplat?",
            answers: {
              speed: {
                text: "Viteză",
                redirect: "speed_whats_wrong"
              },
              payment: {
                text: "Plată"
              },
              quality: {
                text: "Calitate"
              }
            }
          },
    
          speed_whats_wrong: {
            question: "Ce nu e în regulă cu viteza?",
            answers: {
              speed_too_slow: {
                text: "Prea lent",
              },
              speed_not_loading: {
                text: "Blocat la sfârșit.",
              },
              speed_stuck: {
                text: "Nu funcționează.",
              },
            },
          },
    
          payment_whats_wrong: { 
            question: "Ce nu e în regulă cu plata?", 
            answers: {
              payment_still_pending: {
                text: "Am plătit deja, dar tot arată în așteptare...",
              },
              payment_charged_twice: {
                text: "Mi s-a luat de două ori suma.",
              },
            }
          },
          payment_still_pending: { 
            question: "Încercați să o generați din nou?",
            answers: {
              payment_still_pending_regen: {
                text: "Da.",
              },
              payment_still_pending_refund: {
                text: "Nu, doresc rambursarea banilor.",
              },
            },
          },
    
          quality_whats_wrong: { 
            question: "Ce nu e în regulă cu calitatea?",
            answers: {
              quality_bad: {
                text: "Calitatea imaginii este proastă.",
              },
              payment_charged_twice: {
                text: "Imaginea nu este generată complet.",
              },
            },
          }, 
          quality_bad: { 
            question: "Încercați să o generați din nou?",
            answers: {
              quality_bad_regen: {
                text: "Da.",
              },
              quality_bad_refund: {
                text: "Nu, doresc rambursarea banilor.",
              },
            },
          },
    
          refund: {
            question: "Ne pare rău să aflăm că nu a funcționat.",
            answers: {
              refund_refund: {
                text: "Doresc rambursarea banilor.",
              },
              refund_free_token: {
                text: "Doresc un token gratuit."
              }
            }
          }
        },

        pop_refund_title: "Ce imagine te-a făcut să iei această decizie?",
        pop_refund_button: "Acceptă și Trimite",
        pop_refund_button_disabled: "Selectează cel puțin una!",

        pop_redirect_close: "Bine. Sperăm să poți rezolva.",
        pop_redirect_redirecting: "Redirecționare...",

    

        //profile
        first_name: 'Prenume',
        last_name: 'Nume',
        email: 'Email',
        password: 'Parolă',
        confirm_password: 'Confirmă Parola',
        phone: 'Telefon',
        address: 'Adresă',
        city: 'Oraș',
        state: 'Stat',
        zip: 'Cod poștal',
        country: 'Țară',
        county: 'Județ',

        //inspiration
        steps: 'Pași',
        seed: 'Sursă',
        width: 'Lățime',
        height: 'Înălțime',
        artist: 'Artist',
        template: 'Șablon',
        input: 'Intrare',
        price: 'Preț',
        create_amazing_pictures: 'Creazâ Imagini Uimitoare',

        //footer action div
        footer_action_div_top_text: 'Creazâ-ți Noua Operă de Artă',
        footer_action_div_bottom_text: 'Generează ceva uimitor',
        //create page
        positive_top_text: "Spune-ne despre capodopera ta",
        positive_bottom_text: "Introdu niște text pentru ca artistul să creeze imaginea ta, funcționează cel mai bine dacă te gândești la ideile tale ca la titluri pentru rezultatul pe care ți-l dorești.",
        avoid_top_text: 'Este ceva ce vrei să eviți?',
        avoid_bottom_text: "Poți adăuga lucruri pe care nu dorești să le vezi în imaginea ta aici. Ca și mai sus, este cel mai bine să folosești titluri pentru elementele de evitat.",
        artist_top_text: 'Artistul tău',
        artist_bottom_text: "În prezent, sunt disponibili 3 artiști dintre care poți alege. Acești artiști vor face decizii diferite la fiecare pas pentru a crea magia ta. poți vedea unele exemple mai sus; pentru începători, sugerăm artistul 1",
        size_top_text: 'Ce dimensiune îți dorești pentru lucrările tale?',
        size_bottom_text: 'Poți alege între 64px și 2048px pentru înălțime sau lățime.',
        high_top_text: 'Mergi pe MARE?',
        high_bottom_text: 'Artiștii lucrează cel mai bine în jurul valorii de 512px și, dacă depășești acest punct, ar trebui să fie făcut într-o singură direcție sau cu ajutorul acestei setări de înaltă calitate care va împărți lucrarea în sarcini de 512x512 și le va uni.',
        high_input_label: 'Înaltă Calitate',
        steps_top_text: 'Pași',
        steps_bottom_text: 'Fiecare pas îi permite artistului să îmbunătățească imaginea ta, aproximativ 20 de pași îți vor oferi o imagine de bază, 50 sau mai mult ar fi mai buni pentru o calitate mai înaltă.',
        steps_input_label: 'Pași de Eșantionare',
        artist_freedom_top_text: 'Libertatea Artistului',
        artist_freedom_bottom_text: "Aici poți alege greutatea inputului tău în piesă. O setare de bază de aproximativ 7 oferă rezultate care reprezintă atât ideile tale, cât și au o oarecare libertate de expresie. 1 va conține doar un indiciu al inputului tău și 30 va coace cel mai probabil ideile în exces.\nSugerat: 5-15",
        artist_freedom_input_label: 'Scara CFG',
        //create page/quality
        starting_number: 'Număr de Start',
        starting_number_bottom_text: 'Opțional',
        draft_quality: 'Calitate Schiță',
        draft_quality_bottom_text: 'Cel mai cost-eficient',
        basic_quality: 'Calitate de Bază',
        basic_quality_bottom_text: 'Mai rafinat',
        standard_quality: 'Calitate Standard',
        standard_quality_bottom_text: 'Dublu la dimensiune',
        premium_quality: 'Calitate Premium',
        premium_quality_bottom_text: 'Mai rafinat',
        supreme_quality: 'Calitate Supremă',
        supreme_quality_bottom_text: 'Uriaș',
        custom: 'Personalizat',
        custom_bottom_text: 'Nebunie totală',
        //home page
        home_page_first_slider_top_text: 'Creazâ ceva uimitor în câteva minute',
        home_page_first_slider_bottom_text: 'Mașina face toată treaba',
        home_page_second_slider_top_text: 'Creazâ imagini aproape instant',
        home_page_second_slider_middle_text: 'tu furnizezi conceptele pentru artă și ai acces la opțiuni avansate suplimentare dacă vrei să te dai în vânt',
        home_page_second_slider_bottom_text: 'Poți descărca și ai toate drepturile de utilizare asupra operei tale de artă, împreună cu toate informațiile necesare pentru a recrea sau remixa creația ta.',
        idea: 'Ideea',
        write: 'Scrie',
        wait: 'Așteaptă',
        art: 'Artă',
        join: 'Alătură-te',
        join_top_text: 'Alătură-te și creează imagini uimitoare',
        become: <>Devino un Utilizator <br></br> Artfactory </>,
        register_text: "Înregistrează un cont cu noi și obține imagini uimitoare",
        go_to_create_page: 'Mergi la Pagina de Creare',
        go_to_create_page_text: 'Mergi la pagina de creare și realizează-ți propriile imagini',
        write_your_magic: 'Scrie-ți magia',
        write_your_magic_text: 'Scrie-ți magia și mașina va face restul',
        wait_for_the_result: 'Așteaptă rezultatul',
        wait_for_the_result_text: 'Așteaptă rezultatele și bucură-te de noile tale imagini',

        FAQ_short_title: 'Întrebări frecvente',
        FAQ_title: 'Întrebări frecvente',
        FAQ: [
            {
                id: 1,
                title: 'CE ESTE UN ARTIST?',
                content: <>
                    <p>Fiecare artist este un sistem de generare a imaginilor.</p>
                    <p>Combina idei anterioare, inclusiv:</p>
                    <ul>
                        <li>● Motorul de percepție care utilizează augmentarea imaginilor și optimizează iterativ imaginile în raport cu un ansamblu de clasificatori.</li>
                        <br></br>
                        <li>● Imagini ghidate CLIP GAN de la Ryan Murdoch și Katherine Crowson, precum și modificări precum CLIPDraw de la Kevin Frans.</li>
                        <br></br>
                        <li>● Modalități utile de navigare în spațiul latent din Sampling Generative Networks.</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                id: 2,
                title: 'DE CE COSTĂ MAI MULT PENTRU IMAGINI MAI MARI?',
                content: <>
                    <p>Fiecare imagine este generată cadre cu cadru, ceea ce consumă resurse considerabile.</p>
                    <ul>
                        <li>● Schița durează doar câteva secunde.</li>
                        <li>● Calitatea supremă poate dura 30 de minute.</li>
                        <br></br>
                    </ul>
                    <p>În timpul acestui interval, avem hardware-ul cele mai recente pentru rețelele neuronale consumate în totalitate de opera de artă.</p>
                    <p>Putem scala achiziționând mai mult hardware și procesând mai multe în paralel.</p>
                </>,
            },
            {
                id: 3,
                title: 'DE CE NU POT AVEA O IMAGINE MAI MARE?',
                content: 'Lucrăm la o soluție pentru a crea imagini și mai mari. În prezent, suntem restricționați de cantitatea de memorie pe care o poate avea un singur artist. Sperăm să adăugăm supradimensionarea pentru a crește considerabil dimensiunea.'
            },
        ]
    }

}





