import React, {useState, Fragment, useContext, useEffect} from "react";
import { DeleteJob } from "../../../Functions";
import {Navigate} from 'react-router-dom';
import {Link} from "react-router-dom";
import {Tabs,TabPanel} from 'react-tabs';
import Button from '../../button/button-st1';
import {DataContext} from "../../../context/dataContext";
import {GetAllJobs} from "../../../Functions";
import axios from "axios";


const Profile1 = () => {


    const {user, language } = useContext(DataContext)
    const [pending, setPending] = useState([])
    const [imagesFromLocalStorage, setImagesFromLocalStorage] = useState(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
    const [paymentInfo, setPaymentInfo] = useState('')


    useEffect(() => {
        GetAllJobs(user).then(res => {
            setImagesFromLocalStorage(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
        })
    }, []);

    useEffect(() => {
        if (Array.isArray(imagesFromLocalStorage) && imagesFromLocalStorage.length > 0) {
            let pendingJobs = imagesFromLocalStorage.filter(job => job.status === "running")
            if (pendingJobs.length > 0) {
                setPending(true)
                setTimeout(() => {
                    GetAllJobs(user).then(res => {
                        setImagesFromLocalStorage(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
                    })
                }, 4000)
            } else {
                setPending(false)
            }
        }
    }, [imagesFromLocalStorage]);

    useEffect(() => {
        let timer = setTimeout(() => {
                setPaymentInfo('')
            }
            , 5000);
        return () => clearTimeout(timer);
    }, [paymentInfo]);

    if (!user) {
        return <Navigate to='/login'/>
    }
    const finishedJobsLength = imagesFromLocalStorage.length > 0 && imagesFromLocalStorage.filter((item) => item.status === 'finished').length;
    const paymentStatusCheck = async (jobId) => {
        let reqBody = {
            "merchant_id": "artifusion",
            "job_id": jobId
        }
        //https://api.createart.live/payment
        await axios.post('https://api.artifusion.shop/payment', reqBody, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        },)
            .then((res) => {
                if (res?.data) {
                    setPaymentInfo(`Payment status : ${res?.data?.status ?? 'Not paid'}`)
                    if (res.data.status === "failure") {
                        GetAllJobs(user).then(res => {
                            setImagesFromLocalStorage(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
                        })
                    }
                    if (res.data.status === "success") {
                        startJob(jobId)
                        return;
                    }

                }
            })
            .catch((error) => {


            });
    }


    const startJob = async (id) => {

        await axios.get(`https://api.artifusion.shop/start/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                async function getData() {
                    GetAllJobs(user).then(res => {
                        setImagesFromLocalStorage(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
                    })
                }

                getData()
            })
            .catch((error) => {

            });
    }


    return (
        <Fragment>

            <div className="tf-section flat-author-profile flat-explore flat-auctions ">
                <div className="container">
                    <div className="flat-tabs tab-author">
                        <div className="author-profile flex">
                            <div className="feature-profile flex">
                                <div className="infor">
                                    <h3 style={{fontWeight: 'bold'}}
                                        className="fs-24 text-color-1">{user?.first_name ?? ''} {user?.last_name ?? ''}</h3>
                                    <div className="box-price flat-price">
                                        <div className="price flex">
                                            <div className="title-price text-color-1"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {paymentInfo.length > 0 &&
                                <div className="info"
                                     style={{
                                         color: 'black', fontWeight: 'bold', fontSize: '20px'
                                     }}>
                                    {paymentInfo}
                                </div>
                            }

                            <div className="button-profile flex">
                                <h3 className="text-color-1">{finishedJobsLength} {language !== 'fr'? 'Pictures' : 'Des photos'}</h3>

                                <div className="button-edit">
                                    <Button title={language === 'fr' ? 'Editer le profil' : 'Edit Profile'} addclass='sc-button btn-5 style-2 tf-style'
                                            path='/editProfile'/>

                                    <span style={{marginLeft: '5px'}}></span>
                                    <Button onClick={() => {
                                        GetAllJobs(user).then(res => {
                                            setImagesFromLocalStorage(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
                                        })
                                    }} title={language === 'fr' ? 'Rafraîchir': 'Refresh'} addclass='sc-button btn-5 style-2 tf-style'/>
                                </div>
                                <h3 style={{marginLeft: '20px'}}>{pending && 'Please Wait'}</h3>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          
                            flexWrap: 'wrap',
                            textAlign: 'center'
                            

                        }}>
                            {
                                imagesFromLocalStorage.length > 0 && imagesFromLocalStorage.map((item, index) => {

                                    if (item.status === "payment" || item.status === "ready") {
                                        return (
                                            <div style={{  margin:'15px'}} key={index}>
                                                <h3 style={{fontWeight: 'bold', fontSize: '20px'}}>

                                                    {item?.config?.prompt && item?.config?.prompt?.length > 8 ? item?.config?.prompt?.slice(0, 8) + '...' : item?.config?.prompt ?? ''}
                                                    </h3>
                                                <button onClick={() => {
                                                    paymentStatusCheck(item.id)
                                                }} addclass='sc-button btn-5 style-2 tf-style'> Check payment
                                                </button>
                                                <button style={{marginLeft:'10px'}} onClick={() => {
                                                    DeleteJob("https://api.artifusion.shop/job", user, item.id).then((res) => {
                                                        GetAllJobs(user).then((res) => {
                                                            setPaymentInfo('Deleted successfully')
                                                            setImagesFromLocalStorage(res)
                                                        })
                                                    })
                                                }} addclass='sc-button btn-5 style-2 tf-style'> Delete
                                                </button>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <Tabs>
                            {/*<TabList className="menu-tab tab-title">*/}
                            {/*</TabList>*/}
                            <div
                                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px'}}>
                            </div>
                            <TabPanel>
                                {imagesFromLocalStorage.length > 0 &&
                                    imagesFromLocalStorage.sort((a, b) => b.id - a.id).map((item, index) => {
                                        const {status, id, config} = item
                                        if (status !== 'finished') return null
                                        return <div className="sc-card-product sc-card-article blog-article"
                                                    key={item.id}>
                                            <div className="card-media flat-img">
                                                <img style={{height: '300px'}}
                                                     src={`https://api.artifusion.shop/image/${id}`} alt="img"/>
                                            </div>
                                            <div className="content">
                                                <h3>{config.prompt.length > 20 ? <>{config.prompt.slice(0, 50)} ...</> : config.prompt}</h3>
                                                <div className="meta-info mb-17">

                                                </div>
                                                <div className="meta-price flex-one">
                                                    <div className="price flex">

                                                        <div
                                                            className="title-price">{config.width} X {config.height}</div>
                                                    </div>
                                                    <div className="button-place-bid">
                                                        <Link to={`/view?${id}`} data-toggle="modal"
                                                              data-target="#popup_bid"
                                                              className="sc-button btn-3"
                                                        ><span>
                                                            {language !== 'fr'? 'View' : 'Vue'}
                                                        </span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </TabPanel>


                        </Tabs>

                    </div>
                </div>
            </div>
        </Fragment>
    );
};


export default Profile1;
