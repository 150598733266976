import React, {useState, useEffect, useContext} from 'react';
import {Link, NavLink} from 'react-router-dom';
import DarkMode from './DarkMode';
import {useNavigate} from "react-router-dom";
import './styles.scss';
import logo from '../../assets/images/logo/logo.svg'
import {logoutAction} from "../../Functions";
import {DataContext} from "../../context/dataContext";
import {Languages} from "../../languages/languages";


const Header = () => {
    const {user, setUser, language} = useContext(DataContext)
    const [scroll, setScroll] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 200);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };


    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };
    const handleLogout = () => {
        logoutAction().then(() => {
            setUser(false)
            navigate('/')
        })
    }

    return (
        <header id="header_main" className={`header_1 js-header ${scroll ? 'is-fixed' : ''}`}>
            <div className="themes-container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="site-header-inner">
                            <div className="wrap-box flex">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <NavLink to="/" className="main-logo"><img
                                            style={{minWidth: '100px', maxWidth: '180px', width: '10vw'}} src={logo}
                                            alt="createArt"/></NavLink>
                                    </div>
                                </div>
                                <div className={`mobile-button ${menuActive ? 'active' : ''}`}
                                     onClick={handleMenuActive}><span></span></div>
                                <nav style={{right: '150px', left: 'unset'}} id="main-nav"
                                     className={`main-nav ${menuActive ? 'active' : ''}`}>
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            <>
                                                {/*{menus.map((data, idx) => (*/}
                                                {/*    <li key={idx} onClick={() => handleDropdown(idx)}*/}
                                                {/*        className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}*/}

                                                {/*    >*/}
                                                {/*        <Link to={data.links}>{data.name}</Link>*/}
                                                {/*        {*/}
                                                {/*            data.namesub &&*/}
                                                {/*            <ul className="sub-menu">*/}
                                                {/*                {*/}
                                                {/*                    data.namesub.map((submenu) => (*/}
                                                {/*                        <li key={submenu.id} className="menu-item">*/}
                                                {/*                            <NavLink*/}
                                                {/*                                to={submenu.links}*/}
                                                {/*                                onClick={handleDropdown}>{submenu.sub}</NavLink>*/}
                                                {/*                        </li>*/}
                                                {/*                    ))*/}
                                                {/*                }*/}
                                                {/*            </ul>*/}
                                                {/*        }*/}

                                                {/*    </li>*/}
                                                {/*))}*/}
                                                {!!user && <>
                                                    <li className='menu-item'>
                                                        <Link to={'/create'}>{Languages[language].create}</Link>
                                                    </li>
                                                    <li className='menu-item'>
                                                        <Link to={'/editProfile'} >{Languages[language].profile}</Link>
                                                    </li>
                                                    <li className='menu-item'>
                                                        <Link to={'/gallery'} >{Languages[language].gallery}</Link>
                                                    </li>
                                                    <li style={{marginRight:'35px'}} className='menu-item'
                                                        onClick={handleLogout}>
                                                        <Link>{Languages[language].logout}</Link>
                                                    </li>
                                                </>
                                                }
                                                {!user && <>
                                                    <li className='menu-item'>
                                                        <Link to={'/login'}>{Languages[language].create}</Link>
                                                    </li>
                                                    <li className='menu-item'>
                                                        <Link to={'/inspiration'}>{Languages[language].inspiration}</Link>
                                                    </li>
                                                    <li className='menu-item'>
                                                        <Link to={'/login'} >{Languages[language].login}</Link>
                                                    </li>
                                                    <li style={{marginRight:'35px'}} className='menu-item'>
                                                        <Link to={'/register'} >{Languages[language].register}</Link>
                                                    </li>

                                                </>
                                                }
                                            </>
                                        }

                                    </ul>
                                </nav>
                                {/*<div className="flat-search-btn flex">*/}
                                {/*    <div className="header-search flat-show-search" >                                   */}
                                {/*        <div className="top-search">*/}
                                {/*            <form action="#" method="get" role="search" className="search-form">*/}
                                {/*                <input type="search" id="s" className="search-field style" placeholder="Search Here..." name="s" title="Search for" required="" />*/}
                                {/*                <button className="search search-submit" type="submit" title="Search">*/}
                                {/*                    <i className="far fa-search"></i>*/}
                                {/*                </button>*/}
                                {/*            </form>                                           */}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="sc-btn-top" id="site-header">*/}
                                {/*        <Button title='Wallet Connect' addclass='header-slider style wallet fl-button pri-1' path='/connectwallet' />*/}
                                {/*    </div>                                     */}
                                {/*</div>                            */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DarkMode/>

        </header>
    );
}

export default Header;