import React, { useContext, useEffect, useState, useRef } from "react";

import { DataContext } from "../../../context/dataContext";
import { GetAllJobs as getAllJobs } from "../../../Functions";
import SelectableImage from "../components/SelectableImage";
import { Languages } from "../../../languages/languages";

import "../../button/button.scss";

const EMAIL_TO = "info@artifusion.shop";

const RefundProcess = ({ setShown, setPopPage, answers, popPage }) => {
  const { user, language } = useContext(DataContext);
  const [selectedImages, setSelectedImages] = useState([]);
  const sendButtonRef = useRef(null);

  const updateSelected = (id) => {
    let images = selectedImages;

    if (images.includes(id)) {
      images = images.filter((image) => image !== id);
    } else {
      images.push(id);
    }

    setSelectedImages(images);

    if (images.length < 1) {
      sendButtonRef.current.innerHTML =
        Languages[language].pop_refund_button_disabled;
      sendButtonRef.current.classList.add("disabled");
    } else {
      sendButtonRef.current.innerHTML = Languages[language].pop_refund_button;
      sendButtonRef.current.classList.remove("disabled");
    }
  };

  useEffect(() => {}, []);

  let [userJobs, setUserJobs] = useState([]);
  useEffect(() => {
    getAllJobs(user).then((res) => {
      setUserJobs(res);
    });
  }, []);

  return (
    <div className="h-100 d-flex flex-column">
      <h5
      style={{
        color: "black",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "1.5rem"
      }}
      >{Languages[language].pop_refund_title}</h5>

      <div
        className="d-flex flex-row flex-wrap justify-content-center h-100 w-100 p-2"
        style={{
          borderRadius: 5,
          overflowX: "hidden",
        }}
      >
        {Array.isArray(userJobs) &&
          userJobs
            .sort((a, b) => b.id - a.id)
            .map((data, index) => {
              if (data.status === "finished") {
                return (
                  <SelectableImage
                    id={data.id}
                    key={index}
                    updateSelected={updateSelected}
                  />
                );
              }
            })}
      </div>

      <button
        style={{color: "white"}}
        ref={sendButtonRef}
        onClick={(e) => {
          e.preventDefault();
          if (selectedImages.length < 1) {
              return;
            }
            
            let mailSubject = "Refund request ";
            let mailBodyPairs = "";
            let mailBodyImages = "images: ";
            
            popPage.data.answers.map((qa, index) => {
                mailBodyPairs += `${qa ? qa.question : ""}: ${qa.answer} %0D%0A`;
            });
            
            selectedImages.map((image, index) => {
                mailSubject += `${image};`;
                mailBodyImages += `${image};`;
            });
            
            const finalBody = `${mailBodyPairs} %0D%0A%0D%0A ${mailBodyImages}`;
            
            window.open(`mailto:${EMAIL_TO}?subject=${mailSubject}&body=${finalBody}`, "_blank", 'noopener,noreferrer');

            setShown(false)
        }}
        className="btn-action style-2 text-center mt-3 p-3 disabled"
      >
        {Languages[language].pop_refund_button_disabled}
      </button>
    </div>
  );
};

export default RefundProcess;
