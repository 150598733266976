import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Listitem from './bottom-item';
import PropTypes from 'prop-types';

Bottom.propTypes = {
    dataList: PropTypes.array,
};

function Bottom(props) {
    const {classection} = props;
    const [dataTitle] = useState(
        {
            author: '@ EAST AI S.R.L ',
        }
    )
    const [dataList] = useState(
        [
            {
                id: 1,
                title: {
                    en: 'Home',
                    fr: 'Accueil',
                    ro: 'Acasă'
                },
                link: '/',
            },
            {
                id: 2,
                title: {
                    en: 'Terms & Conditions',
                    fr: 'Conditions Générales',
                    ro: 'Termeni și condiții'
                },
                link: '/terms',
            },

        ]
    )

    return (
        <div className={`${classection}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">

                        <div className="widget widget-menu bottom">
                            <ul className="flex link-style-1">
                                {
                                    dataList.map(item => (
                                        <Listitem key={item.id} item={item}/>
                                    ))
                                }
                                <li className={"d-lg-none d-md-flex d-sm-flex"}><a className="text-color-2" href="/" style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    fontFamily: "'Inter', sans-serif",
                                    lineHeight: "28px",
                                    color: "#fff5"
                                }}>&copy; EAST AI S.R.L </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                        <div className="widget widget-menu bottom center"><span></span>
                            {/*<img src="/stripe.png" style={{maxHeight: '40px'}}></img>*/}
                            <br/>
                            <img src="/visa.svg" style={{maxHeight: '40px'}}></img>
                            <img src="/ma_symbol.svg" style={{maxHeight: '40px'}}></img>

                            <span></span>
                        </div>


                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="widget widget-menu flex-two bottom d-none d-lg-flex"><span></span><a className="text-color-3 right" href="/" style={{
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "'Inter', sans-serif",
    lineHeight: "28px"
                            }}>EAST AI S.R.L </a></div>

                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-lg-12 col-md-12">

                        <div className="widget widget-menu flex-two bottom" style={{textAlign:"center"}}>
                            <b></b>
                            <span className="flex center link-style-1">
                                <a className="text-color-4" href="/" style={{

                                }}>EAST AI S.R.L </a>
                                <span>&nbsp;&nbsp;</span>
                                <span>Registration Number: 48398130</span>
                                 <span>&nbsp;&nbsp;</span>
                                <div>📧 support＠artifusion.shop</div>
                                <span>&nbsp;&nbsp;</span>
                                <div className={"text-color-4"}>℡ +40792859945</div>
                                <span>&nbsp;&nbsp;</span>
                                <div>
                                    Boulevard UNIRII 61, Bucharest 030167, Romania
                                </div>
                            </span>
                            <b></b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bottom;
