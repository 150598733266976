import React from "react";
import { useContext } from "react";
import {DataContext} from "../../context/dataContext";
import {Languages} from "../../languages/languages";
import img1 from '../images/icon/1.svg';
import img2 from '../images/icon/2.svg';
import img3 from '../images/icon/3.svg';
import img4 from '../images/icon/4.svg';
import img5 from '../images/icon/friendly-02.svg';
import img6 from '../images/icon/friendly-03.svg';
import img7 from '../images/icon/friendly-04.svg';

const dataFriendly = [
    {
        id: 1,
        img: img1,
        title: {
            en:'Idea',
            fr:'Idee',
        },
        classAction: 'text-color-1'
    },
    {
        id: 2,
        img: img2,
        title: {en:'Write',fr:'Ecrire'},
        classAction: 'text-color-1'
    },
    {
        id: 3,
        img: img3,
        title: {en:'Wait',fr:'Attendre'},
        classAction: 'text-color-1'
    },
    {
        id: 4,
        img: img4,
        title: {en:'Art',fr:'Art'},
    },
    {
        id: 5,
        img: img5,
        title:'Decentralized Platform.',
    },
    {
        id: 6,
        img: img6,
        title:'Crowd Meet Wisdom',
    },
    {
        id: 7,
        img: img7,
        title:'Rewards Meachanism',
    },

]

export default dataFriendly;