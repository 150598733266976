import React , { useState ,useEffect, useContext } from 'react';
import {DataContext} from "../../../context/dataContext";
import { Link , NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo/logo.svg';
import Footeritem from '../footer-item';
import '../styles.scss';

function Footer(props) {
    const {data} = props;
    const [isVisible, setIsVisible] = useState(false);
    const {language} = useContext(DataContext)

    const [dataTitle] = useState(
        {
            subtitle : 'Where the magic happens',
        }
    )

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div>
            <footer style={{marginTop:'80px'}}  id="footer" className="clearfix">
            <div className="container">
                <div className="row" style={{display:'flex',justifyContent:"center"}}>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="widget widget-logo">
                            <div className="flat-box">
                                <div className="logo-footer" id="logo-footer">
                                    <NavLink to="/" className="main-logo"><img id="logo_footer" src={logo} alt="Artifusion" /></NavLink>
                                </div>
                                <p className="sub-widget-logo">{language === 'en'? "Where the magic happens" : "Où la magie opère"}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
           
            </footer>
             {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
        </div>
    );
}

export default Footer;
