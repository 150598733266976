import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import './App.scss';
import './scss/components/animate.scss';
import './scss/components/responsive.scss';
import './scss/components/btn-swiper.scss';
import './scss/components/popup.scss';
import ScrollToTop from "./ScrollToTop";
import DataContextProvider from "./context/dataContext";
import LeftBottomWrapper from './components/layouts/LeftBottomWrapper'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.Fragment>
        <DataContextProvider>
            <BrowserRouter>
                <ScrollToTop/>
                <LeftBottomWrapper/>
                <App/>
            </BrowserRouter>
        </DataContextProvider>
    </React.Fragment>
);

