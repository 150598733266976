import React, {useContext, useEffect} from 'react'
import Create from "./Create";
import {Navigate} from "react-router-dom";
import {DataContext} from "../context/dataContext";
import {EmptyJobCheck} from "../Functions";

export default function CreatePage() {
    const {user} = useContext(DataContext)
    useEffect(() => {
    !!user && EmptyJobCheck(user)
    }, []);
    if (!user) { return <Navigate to='/login'/> }


  return (
    <>
        <Create/>
    </>
  )
}
