const dataFaq = [
    {
        id: 1,
        title: 'What Is An Artist?',
        content: <>
            <p>Each artist is an image generation system.</p>
            <p>It combines previous ideas including:</p>
            <ul>
                <li>● Perception Engines which uses image augmentation and iteratively optimises images against an
                    ensemble of classifiers
                </li>
                <br></br>
                <li>● CLIP guided GAN imagery from Ryan Murdoch and Katherine Crowson as well as modifictions such as
                    CLIPDraw from Kevin Frans
                </li>
                <br></br>
                <li>● Useful ways of navigating latent space from Sampling Generative Networks</li>
                <br></br>
            </ul>
        </>,
    },
    {
        id: 2,
        title: 'WHY DOES IT COST MORE FOR LARGER IMAGES?',
        content: <>
            <p>Every image is generated frame by frame and this consumes considerable resources.</p>
            <ul>
                <li>● Draft takes only a few seconds</li>
                <li>● Supreme can take 30 minutes</li>
                <br></br>
            </ul>
            <p>During this time we have the latest neural network hardware totally consumed by your artwork.</p>
            <p>We are able to scale by purchasing more hardware and process more in parallel.</p>
        </>,
        show: 'show'
    },
    {
        id: 3,
        title: 'WHY CANT I HAVE A BIGGER IMAGE?',
        content: 'We are working on a solution to make even larger images, right now we are constrained by the amount of memory we can have in a single artist. We hope to add the addition of super-sampling to increase the size considerably.',
    },

]

export default dataFaq;