import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

Collection.propTypes = {
    item: PropTypes.object,
};


function Collection(props) {
    const {item} = props;

    return (
        <div className="fl-blog fl-item2">
            <article className="sc-card-article blog-article style-article">
                <div className="flat-tabs card-media media">                                 
                    <div className="content-tab content-img">
                        <div className="content-inner tab-content">
                            <img src={item.img} alt="" />
                        </div>
                    </div>
                    <ul className="menu-tab menu-img">
                        <li className="item-title">
                            <img src={item.imgconlection1} alt="" />
                        </li>
                        <li className="item-title">
                            <img src={item.imgconlection2} alt="" />
                        </li>
                        <li className="item-title">
                            <img src={item.imgconlection3} alt="" />
                        </li>
                    </ul>
                </div>

                                                                                                                                                            
            </article>
        </div>
    );
}

export default Collection;