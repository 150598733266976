import React, {useEffect, useState, Fragment} from 'react';
import '../scss/components/section.scss';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Header from '.././components/header/header.jsx';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import {saveAs} from 'file-saver'
import {DataContext} from "../context/dataContext";
import {DeleteJob, GetAllJobs} from "../Functions";
import {Languages} from "../languages/languages";


function View() {
    const navigate = useNavigate();
    const {user, language} = React.useContext(DataContext)
    const url = window.location.href;
    const imageId = url.split('?').pop();
    const imageData = localStorage.getItem('jobs');
    const jobs = imageData !== undefined && JSON.parse(imageData);
    const findJobById =( jobs && jobs.find(job => job.id === imageId) )|| [];

    if (!user) {
        navigate('/login');
    }
    if (!findJobById?.id) {
        navigate('/gallery')
    }


    const {config, payment, id} = findJobById;
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header/>}
            {!!user && <Fragment>
                <div style={{height: '80vh', minHeight: '800px', marginTop: '80px'}}
                     className="tf-section flat-auctions-details">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="wrap-img">
                                    <div className="img-detail">
                                        <div className="media">
                                            <img style={{maxWidth: '500px', maxHeight: '700px', objectFit: 'cover'}}
                                                 src={`https://api.artifusion.shop/image/${imageId}`}
                                                 alt="createart"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{alignSelf: 'center'}}>
                                <div className="post mtop-7">
                                    <h2 className="fw-5 mt-2">Seed : {findJobById?.meta?.info?.seed} </h2>
                                

                                    <div className="box-price flat-price flex">
                                        <div className="price flex">

                                            <div
                                                className="title-price">{Math.round(payment?.amount)} {payment?.currency} </div>
                                        </div>

                                    </div>
                                    <p className="texts">{config?.prompt}</p>
                                    <div className="information flex">
                                        <div className="column column-1">
                                            <h4 className="title-1 fw-5 text-color-3">Artist: <span
                                                className="">{config?.sampler_index}</span>
                                            </h4>
                                            <h4 className="fw-5 text-color-3">Size: <span
                                                className="">{config?.width} X {config?.height}</span>
                                            </h4>
                                        </div>
                                        <div className="column column-2">
                                            <h4 className="title-1 fw-5 text-color-3">steps: <span
                                                className="">{config?.steps}</span>
                                            </h4>

                                        </div>
                                    </div>

                                    <div className="button-place-bid">
                                        <button data-toggle="modal" data-target="#popup_bid"
                                                className="sc-button btn-3"
                                                onClick={() => {
                                                    DeleteJob("https://api.artifusion.shop/job", user, id).then((res) => {
                                                        GetAllJobs(user).then((res) => {
                                                            navigate('/gallery')
                                                        })
                                                    })
                                                }}><span>{Languages[language].delete}</span>
                                        </button>
                                        <button style={{marginLeft: '20px'}} data-toggle="modal" data-target="#popup_bid"
                                                className="sc-button btn-3"
                                                onClick={() => {
                                                   
                                                    navigate(`/image_settings?id=${imageId}`)
                                                }}><span>Edit</span>
                                        </button>
                                        <button style={{marginLeft: '20px'}} data-toggle="modal"
                                                data-target="#popup_bid" className="sc-button btn-3"
                                                onClick={() => {
                                                    let randomNumber = Math.floor(Math.random() * 100000)
                                                    saveAs(`https://api.artifusion.shop/image/${imageId}`, `image${randomNumber}.jpg`)
                                                }}><span>{Languages[language].download}</span>
                                        </button>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>}


            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default View;