import React,{useEffect,useState} from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import PageTitle from '../components/page-title/page-title-v1';
import Flathelp from '../components/flat-help';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';
import {Link, useNavigate} from 'react-router-dom';
import {EmptyJobCheck, registerAction} from "../Functions";


function Register(props) {
    const [error, setError] = useState('');
    const [registerCredentials, setRegisterCredentials] = useState({
        email: '',
        password: '',
    });
    useEffect(() => {
        const timer = setTimeout(() => {
            setError('')
        }, 5000);
        return () => clearTimeout(timer);
    }, [error]);

    const navigate = useNavigate();
    const handleRegister = async (e) => {
        const {email, password} = registerCredentials;
        e.preventDefault();

        await registerAction("https://api.artifusion.shop/register", email, password).then(res => {

            EmptyJobCheck(res.data);
        }).then(() => {
            navigate('/login')
        })
            .catch(c => {
                setError(c?.response?.data?.error?.detail ?? 'Something went wrong')
            });
    }

    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header/>}

            <div style={{marginTop: '50px'}} className="tf-section flat-login flat-auctions-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="wrap-login">
                                <div className="box-login post">
                                    <img style={{width: '100px'}} src={require('../assets/images/mark/login3.png')}
                                         alt="createart"
                                         className="absolute mark-page3"/>
                                    <img style={{width: '60px'}} src={require('../assets/images/mark/register.png')}
                                         alt="createart"
                                         className="absolute mark-login1"/>
                                    <img style={{width: '80px'}} src={require('../assets/images/mark/login2.png')}
                                         alt="createart"
                                         className="absolute mark-login2 animate-rotate"/>

                                    <img src={require('../assets/images/backgroup-secsion/bg-login.png')}
                                         alt="createart" className="absolute mark-login"/>

                                    <div className="heading-login register">
                                        <h2 className="fw-5">Register</h2>
                                        <div className="flex"><p> Already have an account? </p><Link to="/login"
                                                                                                     className="text-p text-color-3 fw-6">Login</Link>
                                        </div>
                                    </div>
                                    <form action="#" id="contactform">
                                        <div className="form-login flat-form flex-one">
                                            <div className="info-login">

                                                <fieldset>
                                                    <p className="title-infor-account">Email</p>
                                                    <input onChange={(e) => {
                                                        setRegisterCredentials({
                                                            ...registerCredentials,
                                                            email: e.target.value
                                                        })
                                                    }}
                                                           type="email" id="email" className="tb-my-input" name="email"
                                                           placeholder="info@gmail.com" aria-required="true"
                                                           required=""/>
                                                </fieldset>
                                                <fieldset className="style-pass">
                                                    <p className="title-infor-account">Password</p>
                                                    <input onChange={(e) => {
                                                        setRegisterCredentials({
                                                            ...registerCredentials,
                                                            password: e.target.value
                                                        })
                                                    }}

                                                           type="password" name="password" className="tb-my-input"
                                                           id="password" placeholder="*********************************"
                                                           aria-required="true" required=""/>
                                                </fieldset>
                                                <div className="row-form style-1 flex-two">
                                                    <label className="flex align">
                                                        <input type="checkbox"/>
                                                        <span className="btn-checkbox flex-two"></span>
                                                        <span className="text-p text-color-7">Remember Me</span>
                                                    </label>

                                                </div>
                                                {error.length > 0 && <div className="error-message"
                                                                          style={{
                                                                              display: 'flex',
                                                                              justifyContent: 'center',
                                                                              color: 'red',
                                                                              fontSize: '18px',
                                                                              marginBottom: '15px'
                                                                          }}>{error}</div>}

                                                <button
                                                    onClick={(e) => handleRegister(e)}
                                                    className="submit button-login">Register
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {<Flathelp/>}
            {<Footer data={dataFooter}/>}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default Register;