import React, { useState, useContext, useEffect } from "react";
import RangeSlider from "../components/RangeSlider/RangeSlider";
import { Dropdown } from "react-bootstrap";
import Header from "../components/header/header";
import { DataContext } from "../context/dataContext";
import { EmptyJobCheck } from "../Functions";
import axios from "axios";
import Footer from "../components/footer/footer-v1";
import dataFooter from "../assets/fake-data/data-footer";

const ImageToImage = () => {
  const query = new URLSearchParams(window.location.search);
  const denoise = Array.from(Array(100).keys()).map((i) => (i + 1) / 100);
  const mask_blur = Array.from(Array(65).keys());
  const masked_content = [
    { value: 0, label: "Fill", desc:'Fill it with colors of the image' },
    { value: 1, label: "Original", desc:'Keep it whatever was there originally' },
    { value: 2, label: "Latent Noise", desc:'Fill it with latent space noise' },
    { value: 3, label: "Latent Nothing", desc:'Fill with latent space zeroes' },
  ];
  const imageId = query.get("id");
  const { user } = useContext(DataContext);
  const imageToCustomize = `https://api.artifusion.shop/image/${imageId}`;
  const originalImageData = findItemInLocalStorage(imageId);
  const [settings, setSettings] = useState({
    steps: 30,
    cfg_scale: 7,
    prompt: "",
    negative_prompt: "",
    restore_faces: false,
    sampler_index: "Euler a",
    mask_blur: originalImageData?.config?.mask_blur ?? 4,
    denoising_strength: originalImageData?.config?.denoising_strength ?? 0,
    inpainting_fill: originalImageData?.config?.inpainting_fill ?? 0,
  });
  const price = Math.max(25,originalImageData.payment.amount /(originalImageData.config.type === "txt2img" ? 2 : 1));

  const base64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  function findItemInLocalStorage(id) {
    const imageData = localStorage.getItem("jobs");
    const jobs = imageData !== undefined && JSON.parse(imageData);
    if (!jobs) return undefined;
    const filteredData = jobs.filter((job) => job.id === id);
    return filteredData[0];
  }


  const sendJobWithPayments = async () => {
    if (!originalImageData) return;
    const canvas = document.getElementById("canvas");
    const base64Canvas = canvas.toDataURL("image/png");
    const base64Image = await base64FromUrl(imageToCustomize);
    const emptyJob = await EmptyJobCheck(user);

    let config = {
      init_images: [base64Image],
      seed: -1,
      steps: settings.steps,
      mask: base64Canvas,
      cfg_scale: settings.cfg_scale,
      width: originalImageData?.config?.width ?? 512,
      height: originalImageData?.config?.height ?? 512,
      prompt: settings.prompt,
      negative_prompt: settings.negative_prompt,
      restore_faces: settings.restore_faces,
      type: "img2img",
      enable_hr: false,
      firstphase_width: 0,
      firstphase_height: 0,
      denoising_strength: 0.75,
      sampler_index: originalImageData?.config?.sampler_index ?? "Euler a",
    };

    let dataToSend = {
      merchant_id: "artifusion",
      save: true,
      job: {
        ...emptyJob,
        config: config,
        status: "init",

        payment: {
          method: "justtest",
          amount: price,
          currency: "EUR",
          user: {
            ...originalImageData.payment.user,
          },
        },
      },
    };

    await axios
      .post(`https://api.artifusion.shop/pay`, dataToSend, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        removeEmptyJobFromLocalStorage();
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeEmptyJobFromLocalStorage = () => {
    let jobs = JSON.parse(localStorage.getItem("jobs"));
    let filteredJobs = jobs.filter((job) => job.status !== "init");
    localStorage.setItem("jobs", JSON.stringify(filteredJobs));
  };

  useEffect(() => {
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    resize();
    var pos = { x: 0, y: 0 };
    window.addEventListener("resize", resize);
    canvas.addEventListener("mousemove", draw);
    canvas.addEventListener("mousedown", setPosition);
    canvas.addEventListener("mouseenter", setPosition);

    function setPosition(e) {
      pos.x = e.offsetX;
      pos.y = e.offsetY;
    }

    function resize() {
      ctx.canvas.width = originalImageData?.config?.width ?? 512;
      ctx.canvas.height = originalImageData?.config?.height ?? 512;
    }

    function draw(e) {
      if (e.buttons !== 1) return;
      ctx.beginPath(); // begin
      ctx.lineWidth = 25;
      ctx.lineCap = "round";
      ctx.strokeStyle = "#fff";
      ctx.moveTo(pos.x, pos.y); // from
      setPosition(e);
      ctx.lineTo(pos.x, pos.y); // to
      ctx.stroke(); // draw it!
    }
    canvas.style.backgroundImage = `url(${imageToCustomize})`;
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#130518",
      }}
    >
      <Header />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <canvas
          style={{
            borderRadius: "15px",
            marginTop: "150px",
            marginBottom: "20px",
          }}
          id="canvas"
        ></canvas>
      </div>
      <div
        className="info-title info-account"
        style={{
          maxWidth: "500px",
          margin: "0 auto",
          marginBottom: "50px",
        }}
      >
        <span style={{
          fontSize: "15px",
          textAlign: "center",
          color: "#fff",
          marginBottom: "20px",
          display: "block",

        }}>{
          masked_content.filter((item) => item.value === settings["inpainting_fill"])[0].desc ?? "None"

        }</span>
        <Dropdown>
          <Dropdown.Toggle
            style={{
              width: "100%",
              height: "100%",
              padding: "10px",
            }}
            id="dropdown-basic"
          >
            {masked_content.filter(
              (item) => item.value === settings["inpainting_fill"]
            )[0].label ?? "None"}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: 0 }}>
            {masked_content.map((data) => (
              <Dropdown.Item
                onClick={() => {
                  setSettings({
                    ...settings,
                    inpainting_fill: data.value,
                  });
                }}
                key={data.value}
                to="#"
              >
                {data.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div className="range_slider_wrapper" style={{ marginBottom: "15px" }}>
          <p
            style={{
              alignSelf: "center",
              marginTop: "10px",
              maxWidth: "550px",
            }}
          >
            Edit strength
          </p>
          <span style={{textAlign:'center',opacity:'0.7'}}> 
          How much to apply the mask to the image.
          </span>
         

          <RangeSlider
            steps={denoise}
            indexOfDefault={denoise.findIndex(
              (i) => i === settings.denoising_strength
            )}
            callback={(value) => {
              setSettings({
                ...settings,
                denoising_strength: value.value,
              });
            }}
          />
        </div>
        <div className="range_slider_wrapper" style={{ marginBottom: "15px" }}>
          <p
            style={{
              alignSelf: "center",
              marginTop: "10px",
              maxWidth: "550px",
            }}
          >
            Mask Blur
          </p>
          <span style={{textAlign:'center',opacity:'0.7'}}>How much to blur the mask before processing, in pixels. </span>

          <RangeSlider
            steps={mask_blur}
            indexOfDefault={mask_blur.findIndex(
              (i) => i === settings.mask_blur
            )}
            callback={(value) => {
              setSettings({
                ...settings,
                mask_blur: value.value,
              });
            }}
          />
        </div>

        <fieldset>
          <p className="title-infor-account">Write here your change</p>
          <input
            onChange={(e) => {
              setSettings({
                ...settings,
                prompt: e.target.value,
              });
            }}
            type="text"
            placeholder="write here what you want to see in your image"
            required
          />
        </fieldset>
        <fieldset>
          <p className="title-infor-account">Negative</p>
          <input
            onChange={(e) => {
              setSettings({
                ...settings,
                negative_prompt: e.target.value,
              });
            }}
            type="text"
            placeholder="write here what you don't want to see in your image"
            required
          />
        </fieldset>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: "50px",
        }}
      >
        <button
          className="btn-action style-2"
          onClick={() => {
            sendJobWithPayments();
          }}
        >
          {price} {originalImageData.payment.currency}
        </button>
      </div>
      <Footer data={dataFooter} />
    </div>
  );
};

export default ImageToImage;
